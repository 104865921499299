import React, { useContext, useEffect, useState } from "react";
import { getTransactionInfo, postEmail } from "../apis/services";
import { AppContext, TransactionContext } from "../App";
import LCButton from "../components/LCButton";
import LCInput from "../components/LCInput";
import LCTitleSubtitle from "../components/LCTitleSubtitle";
import {
  ENTER_VERIFICATION_CODE,
  INITIATE_PURCHASE,
} from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import useTransactionRef from "../hooks/useTransactionRef";
import { emailRegex } from "../utility";
import LCScreenLoader from "../components/LCScreenLoader";

const EnterEmail = ({
  tempEmail,
  handleSubmittedEmail,
}: {
  tempEmail: string;
  handleSubmittedEmail: (email: string) => void;
}) => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);
  const transactionRef = useTransactionRef();

  const isFieldFixed = (field: string) =>
    transaction.info.fixed_properties?.includes(field);

  const zusTransactionRef = useTransactionRef();

  const [email, setEmail] = useState(tempEmail);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingTransactionInfo, setIsFetchingTransactionInfo] =
    useState(true);

  useEffect(() => {
    setEmail(tempEmail);
  }, [tempEmail]);

  useEffect(() => {
    // Happens on email page
    if (
      isFieldFixed("email") ||
      transaction.info.authenticated ||
      transaction.info.state !== "initiated" ||
      transaction.submittedDetails.email ||
      transaction.submittedDetails.bankAccountDetails
    ) {
      if (app.previousScreen === ENTER_VERIFICATION_CODE) {
        app.setCurrentScreen(INITIATE_PURCHASE);
      } else {
        app.setCurrentScreen(ENTER_VERIFICATION_CODE);
      }
    }
  }, []);

  useEffect(() => {
    getTransactionInfo(transactionRef)
      .then((res) => {
        if (res) {
          transaction.setInfo(res);
          setIsFetchingTransactionInfo(false);
        }
      })
      .catch(() => {
        app.setErrorMessage("Unable to fetch transaction, kindly refresh");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinueClick = () => {
    setIsSubmitting(true);
    handleSubmittedEmail(email);
    postEmail({ email }, zusTransactionRef)
      .then(() => {
        app.setCurrentScreen(ENTER_VERIFICATION_CODE);
      })
      .catch((e) => {
        setIsSubmitting(false);
      });
  };

  const handleBackClick = () => {
    app.setCurrentScreen(INITIATE_PURCHASE);
  };
  const isEmailValid = () => !!email.trim().length && emailRegex.test(email);

  return (
    <div className="flex flex-col">
      <LCNavbar hasBack handleBackClick={handleBackClick} />
      {isFetchingTransactionInfo ? (
        <LCScreenLoader />
      ) : (
        <form className="flex flex-col items-center justify-between w-full">
          <div className="w-full mb-32 text-center">
            <LCTitleSubtitle
              title={transaction?.info.merchant_name}
              subtitle=" Kindly provide your email address to continue"
            />
            <LCInput
              type="email"
              label="Email Address"
              placeholder="johnsmith@xyz.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <LCButton
            type="submit"
            isDisabled={!isEmailValid()}
            isLoading={isSubmitting}
            text="Continue"
            onClick={handleContinueClick}
          />
        </form>
      )}
    </div>
  );
};

export default EnterEmail;
