import { useContext, useEffect } from "react";
import { AppContext, TransactionContext } from "../App";
import { getTransactionInfo } from "../apis/services";
import useTransactionRef from "../hooks/useTransactionRef";
import {
  REFRESH_CONFIRM_PAYMENT_FREQ,
  TRANSACTION_STATE,
} from "../constants/transaction";
import { PAYMENT_STATUS, TRANSACTION_WARNING } from "../constants/screen";
import { sendMessageToParent } from "../utility";
import WhiteSpinner from "../assets/icons/others/whiteSpinner.svg";

const WaitForPaymentMomo = () => {
  const { setCurrentScreen, setErrorMessage, isPopup } = useContext(AppContext);
  const { info, setInfo } = useContext(TransactionContext);
  const zusTransactionRef = useTransactionRef();

  useEffect(() => {
    if (info?.is_abandoned) {
      setCurrentScreen(TRANSACTION_WARNING);
      return;
    }

    const interval = setInterval(() => {
      getTransactionInfo(zusTransactionRef)
        .then((res) => {
          if (res?.is_abandoned) {
            setCurrentScreen(TRANSACTION_WARNING);
            return;
          }
          if (
            res?.state !== TRANSACTION_STATE.INITIATED &&
            res?.state !== TRANSACTION_STATE.PENDING
          ) {
            setInfo(res);
            if (res?.state === TRANSACTION_STATE.PAYMENT_RECEIVED) {
              setCurrentScreen(PAYMENT_STATUS);
            } else if (
              res?.state === TRANSACTION_STATE.SENT_CRYPTO ||
              res?.state === TRANSACTION_STATE.SENDING_CRYPTO ||
              res?.state === TRANSACTION_STATE.BOUGHT_CRYPTO ||
              res?.state === TRANSACTION_STATE.COMPLETED
            ) {
              setCurrentScreen(PAYMENT_STATUS);
            }
          }
        })
        .catch((e) => {
          setErrorMessage(e?.msg);
        });
    }, REFRESH_CONFIRM_PAYMENT_FREQ * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalCloseClick = () => {
    if (!window.confirm("Are you sure you want to close this widget?")) return;
    if (isPopup) {
      sendMessageToParent({ key: "buy-closeModal" });
    } else {
      window.location = `${
        info?.callback_url || "https://localramp.co"
      }?ref=${zusTransactionRef}` as string & Location;
    }
  };

  return (
    <>
      <div className="flex flex-col gap-6 items-center justify-center h-full text-center min-h-[302px]">
        <p className="mb-6 text-lg text-black-80">
          Kindly wait while we confirm your payment. It won’t take long
        </p>
        <div className="animate-spin">
          <img src={WhiteSpinner} alt="spinner" className="w-full h-full" />
        </div>
        <p className="pb-8 text-sm text-black-80">
          If you can’t wait, click <span className="text-purple">close</span>{" "}
          below. You’ll receive value once we receive your payment
        </p>
        <button
          className="text-sm font-semibold text-purple"
          onClick={handleModalCloseClick}
        >
          Close modal
        </button>
      </div>
    </>
  );
};

export default WaitForPaymentMomo;
