import React from "react";
import { afterHyphen, before_ } from "../utility";
import { DropDownDatatype } from "./LCDropdown";

interface ILCDropdownListItem {
  option: any;
  onSelect?: (v: DropDownDatatype) => void;
  logo: any;
}

const LCDropdownListItem = ({
  option,
  onSelect,
  logo,
}: ILCDropdownListItem) => {
  const handleSelectedItem = () => {
    onSelect && onSelect(option);
  };
  return (
    <button
      onClick={handleSelectedItem}
      className="flex items-center justify-between w-full py-2 pl-8 pr-5 bg-white outline-none"
    >
      <div
        className="grid items-center gap-x-2"
        style={{ gridTemplateColumns: "auto auto" }}
      >
        <div
          className={`w-4 h-4 ${
            !logo?.length && "bg-black-20"
          } rounded-full flex items-center justify-center`}
        >
          <img src={logo} alt="" className="w-full h-full rounded-full" />
        </div>
        <div>
          <p className="text-xs text-left uppercase text-black-80 grotesk">
            <span className="font-medium">
              {before_(option?.fiat || option?.coin)}
            </span>
            <span className="text-black-40">
              {" - "}
              {afterHyphen(option?.name)}
            </span>
          </p>
        </div>
      </div>
      <i className="ri-arrow-right-s-line ri-md text-black-40"></i>
    </button>
  );
};

export default LCDropdownListItem;
