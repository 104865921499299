import React, { useContext, useState } from "react";
import LCNavbar from "../containers/LCNavbar";
import { AppContext } from "../App";
import { SEND_WITH_MOMO, WAIT_FOR_MOMO } from "../constants/screen";
import LCTitleSubtitle from "../components/LCTitleSubtitle";
import LCButton from "../components/LCButton";
import { useMutation } from "react-query";
import { sendMomoOTP } from "../apis/services";
import useTransactionRef from "../hooks/useTransactionRef";
import LCInput from "../components/LCInput";

const MomoOTP = () => {
  const transactionRef = useTransactionRef();
  const { setCurrentScreen, setErrorMessage } = useContext(AppContext);

  const [verificationCode, setVerificationCode] = useState("");

  const { mutate, isLoading } = useMutation(sendMomoOTP, {
    onSuccess: () => setCurrentScreen(WAIT_FOR_MOMO),
    onError: (error: any) => setErrorMessage(error.message),
  });

  const handleBackClick = () => setCurrentScreen(SEND_WITH_MOMO);
  const handleContinueClick = () => {
    mutate({
      otp: verificationCode,
      reference: transactionRef,
    });
  };

  return (
    <div>
      <LCNavbar hasBack handleBackClick={handleBackClick} />
      <div className="flex flex-col gap-6">
        <div className={`text-center w-full`}>
          <LCTitleSubtitle
            title="Confirm OTP"
            subtitle="Kindly input the OTP sent to your phone number"
          />
        </div>
        <LCInput
          type="number"
          label="OTP"
          placeholder="Enter OTP"
          value={verificationCode}
          onChange={(e) => {
            setVerificationCode(e.target.value);
          }}
        />
        <LCButton
          type="submit"
          text="Continue"
          onClick={handleContinueClick}
          isDisabled={!verificationCode}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default MomoOTP;
