import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";

const LCSwitchTab = ({
  handleSwitch,
  value,
  tabOne,
  tabTwo,
}: {
  handleSwitch: (v: boolean) => void;
  value: boolean;
  tabOne: string;
  tabTwo: string;
}) => {
  const app = useContext(AppContext);
  const [isOn, setIsOn] = useState(value);
  const handleSwitchClick = (v: boolean) => {
    setIsOn(v);
    handleSwitch(v);
  };
  useEffect(() => {
    setIsOn(value);
  }, [value]);
  return (
    <button
      className="bg-[#F2F2F2] w-52 h-11 mx-auto rounded-full grid grid-cols-2 items-center p-1 text-xs cursor-pointer text-black-20 grotesk"
      onClick={() => handleSwitchClick(!isOn)}
    >
      {isOn}
      <p
        className={`h-full  rounded-full flex justify-center items-center ${
          isOn && "text-white"
        }`}
        style={{
          backgroundColor: isOn ? app.colour.dark : "",
        }}
      >
        {tabOne}
      </p>
      <p
        className={`h-full rounded-full flex justify-center items-center font-semibold ${
          !isOn && "text-white"
        }`}
        style={{
          backgroundColor: !isOn ? app.colour.dark : "",
        }}
      >
        {tabTwo}
      </p>
    </button>
  );
};

export default LCSwitchTab;
