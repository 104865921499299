import React from "react";

const LCTitleSubtitle = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) => {
  return (
    <div className="space-y-2 mb-4">
      <h1 className="text-black text-base font-semibold">{title}</h1>
      <p className="text-xs text-black-80 font-normal max-w-[220px] mx-auto">
        {subtitle}
      </p>
    </div>
  );
};

export default LCTitleSubtitle;
