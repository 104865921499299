import React, { useContext, useState } from "react";
import { AppContext } from "../App";
import { before_, commalize, formatProcessorFee } from "../utility";
import LCQuoteRefreshInfo from "./LCQuoteRefreshInfo";

interface ILCSummary {
  label?: string;
  amountToGet: number;
  currencyToGet: string | number;
  amountToPay: number;
  currencyToPay: string | number;
  shouldRefreshTimer: boolean;
  quote: { rate: any; info: any };
}

const LCQuote = (props: {
  amountToGet: number;
  currencyToGet: string | number;
  amountToPay: number;
  currencyToPay: string | number;
  data: { rate: any; info: any };
}) => {
  return (
    <div className="space-y-1">
      <div className="space-y-1 text-[10px] font-semibold">
        <div className="flex justify-between items-center text-black-80">
          <p className="text-black-40">
            {props.amountToGet} {props.currencyToGet}
          </p>
          <p className="">
            {props.currencyToPay} {commalize(props.amountToPay)}
          </p>
        </div>
        <div className="flex justify-between items-center text-black-80">
          <p className="text-black-40">Network Fee</p>
          <p className="">
            {props.data.rate?.network_fee}{" "}
            {before_(props.data.rate?.receiver_currency)}
          </p>
        </div>
        <div className="flex justify-between items-center text-black-80">
          <p className="text-black-40">Processing Fee</p>
          <p className="">
            {props.currencyToPay}{" "}
            {formatProcessorFee(
              props.data.rate?.processor_fee,
              Number(props.amountToPay)
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
const LCSummary = ({
  label = "Summary",
  amountToGet,
  currencyToGet,
  amountToPay,
  currencyToPay,
  shouldRefreshTimer,
  quote,
}: ILCSummary) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isPopup } = useContext(AppContext);
  return (
    <div className="w-full">
      <div className="space-y-1 flex flex-col items-start">
        <p className="text-xs font-medium text-black-40">{label}</p>
        <button
          className={`border-b border-b-black-20 w-full text-xs font-semibold pb-2 flex items-center text-left bg-white`}
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
        >
          <div className="w-full text-black-40">
            You get{" "}
            <span className="text-black">
              {amountToGet} {currencyToGet}
            </span>{" "}
            for{" "}
            <span className="text-black">
              {commalize(amountToPay)} {currencyToPay}
            </span>
          </div>
          <div className="outline-none">
            {isOpen ? (
              <i className="ri-arrow-up-s-fill"></i>
            ) : (
              <i className="ri-arrow-down-s-fill"></i>
            )}
          </div>
        </button>
      </div>
      <LCQuoteRefreshInfo shouldRefreshTimer={shouldRefreshTimer} />
      {isOpen ? (
        <LCQuote
          {...{ amountToGet, currencyToGet, amountToPay, currencyToPay }}
          data={quote}
        />
      ) : null}
    </div>
  );
};

export default LCSummary;
