import { useContext, useState } from "react";
import LCInput from "../LCInput";
import LCDropdownListItem from "./LCDropdownListItem";
import { AppContext } from "../../App";

interface ILCDropdownListSearchable {
  searchLabel: string;
  searchPlaceholder: string;
  allOptions: any[];
  onOptionSelect: (v: any) => void;
  onClose: (v: boolean) => void;
}

const LCDropdownListSearchable = ({
  searchLabel,
  searchPlaceholder,
  allOptions,
  onOptionSelect,
  onClose,
}: ILCDropdownListSearchable) => {
  const { isPopup } = useContext(AppContext);
  const [searchParam, setSearchParam] = useState("");
  const [optionsToDisplay, setOptionsToDisplay] = useState(allOptions);

  const handleSearchParamChange = (searchParam: string) => {
    setSearchParam(searchParam);
    if (!searchParam.trim().length) {
      setOptionsToDisplay(allOptions);
      return;
    }
    const queryToLowercase = searchParam.toLowerCase();
    const rankedIndex = allOptions.filter((option) =>
      option.name.toLowerCase().includes(queryToLowercase)
    );
    setOptionsToDisplay(rankedIndex);
  };

  return (
    <div className="absolute top-0 left-[-12px] right-0 h-full w-full bg-white pb-7 rounded-3xl z-[60]">
      <div className="absolute w-full bg-white rounded-3xl pt-7">
        <div className="flex items-center justify-between px-8 pt-2 mb-6">
          <p className="text-base font-semibold text-black">Select </p>
          <button
            onClick={() => {
              onClose(false);
            }}
          >
            <i className="ri-close-fill ri-xl" style={{ color: "black" }} />
          </button>
        </div>
      </div>
      <div className="mt-20 space-y-4">
        <div className="pl-8 sticky w-[288px] bg-white">
          <LCInput
            label={searchLabel}
            placeholder={searchPlaceholder}
            value={searchParam}
            onChange={(e: any) => {
              handleSearchParamChange(e.target.value);
            }}
          />
        </div>
        <div
          className={`${
            isPopup ? "max-h-[220px]" : "max-h-[250px]"
          } overflow-y-auto`}
        >
          <div>
            {optionsToDisplay.map((option, index) => (
              <LCDropdownListItem
                logo={option.image}
                key={index}
                option={option}
                onSelect={onOptionSelect}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LCDropdownListSearchable;
