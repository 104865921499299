import { useContext, useEffect, useState } from "react";
import LCNavbar from "../containers/LCNavbar";
import LCTitleSubtitle from "../components/LCTitleSubtitle";
import LCInput from "../components/LCInput";
import LCButton from "../components/LCButton";
import LCDropdown from "../components/LCDropdownNormal";
import { AppContext, TransactionContext } from "../App";
import { getPaymentMethods, setDestination } from "../apis/services";
import {
  MOMO_OTP,
  SELECT_PAYMENT_METHOD,
  WAIT_FOR_MOMO,
} from "../constants/screen";
import { getJsonFromStorage, hasStorageAccess } from "../utility/storage";
import { STORAGE_CHECKOUT_DETAILS } from "../constants/storage";
import useTransactionRef from "../hooks/useTransactionRef";
import { useMutation } from "react-query";
import LCScreenLoader from "../components/LCScreenLoader";
import { getCountryPhoneCode } from "../utility";

const SendWithMobile = () => {
  const zusRef = useTransactionRef();

  const { setCurrentScreen, setErrorMessage } = useContext(AppContext);
  const { submittedDetails, info } = useContext(TransactionContext);

  const [options, setOptions] = useState<any[]>([]);
  const [isFetchingMethods, setIsFetchingMethods] = useState(true);
  const [networkProvider, setNetworkProvider] = useState(
    submittedDetails?.momo_id
  );
  const [phoneNumber, setPhoneNumber] = useState("");

  const { mutate, isLoading } = useMutation(setDestination, {
    onSuccess: (data) => {
      if (data?.data?.data?.is_otp_required) return setCurrentScreen(MOMO_OTP);
      return setCurrentScreen(WAIT_FOR_MOMO);
    },
    onError: (error: any) => setErrorMessage(error.response.data.msg),
  });

  const handleContinueClick = () => {
    mutate({
      data: {
        payment_method_id: networkProvider,
        phone_network: networkProvider,
        phone_number: getCountryPhoneCode(info?.country_code).concat(
          phoneNumber
        ),
        country_code: info?.country_code,
      },
      zusRef: !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference,
    });
  };
  const handleBackClick = () => setCurrentScreen(SELECT_PAYMENT_METHOD);

  useEffect(() => {
    getPaymentMethods({
      currency: info.sender_currency || submittedDetails.currencyToPay?.fiat,
      country_code:
        info.country_code || submittedDetails.currencyToPay?.country_code,
      reference: zusRef,
    })
      .then((res) => {
        setOptions(res?.payment_methods);
        setIsFetchingMethods(false);
      })
      .catch((e) => {
        setErrorMessage(e?.msg);
      });
  }, []);

  return (
    <div>
      <LCNavbar hasBack handleBackClick={handleBackClick} />
      {isFetchingMethods ? (
        <LCScreenLoader />
      ) : (
        <div className="flex flex-col gap-6">
          <div className={`text-center w-full`}>
            <LCTitleSubtitle
              title="Mobile money details"
              subtitle="Kindly provide your phone number and network provider"
            />
          </div>
          <LCDropdown
            onOptionChange={(value) => setNetworkProvider(value.id)}
            dropdownTitle={"Network Provider"}
            dropdownPlaceholder={"Select provider"}
            options={options}
            defaultOption={options?.find(
              (option) => option?.id === submittedDetails?.momo_id
            )}
            isDisabled={false}
          />
          <LCInput
            type="tel"
            label="Phone Number"
            placeholder="8123456789"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
          <LCButton
            type="submit"
            text="Continue"
            onClick={handleContinueClick}
            isDisabled={!networkProvider || phoneNumber.length < 8}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default SendWithMobile;
