import React, { useContext } from "react";
import { AppContext, TransactionContext } from "../App";
import LCButton from "../components/LCButton";

// eslint-disable-next-line import/no-unresolved
import Loader from "../assets/icons/others/payConfirmationLoader.svg";
import { getJsonFromStorage, hasStorageAccess } from "../utility/storage";
import { STORAGE_CHECKOUT_DETAILS } from "../constants/storage";
import { sendMessageToParent } from "../utility";
import useTransactionRef from "../hooks/useTransactionRef";

const PaymentConfirmation = ({
  handleViewBankDetails,
  canShowDetails = true,
}: {
  handleViewBankDetails?: () => void;
  canShowDetails?: boolean;
}) => {
  const transaction = useContext(TransactionContext);
  const app = useContext(AppContext);
  const zusRef = useTransactionRef();

  const handleCloseTransactionClick = () => {
    if (app.isPopup) {
      sendMessageToParent({ key: "buy-closeModal" });
    }
    window.location = `${transaction?.info?.callback_url}?ref=${
      !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference
    }` as string & Location;
  };
  return (
    <div className="">
      <div className={`${canShowDetails ? "mt-4" : "mt-20"} mb-5 space-y-5`}>
        <h1 className="text-base text-black font-semibold w-full max-w-[294px] mx-auto text-center">
          Kindly wait while we confirm your payment. It won’t take long
        </h1>
        <div className="animate-spin duration-[3000ms] w-10 h-10 flex items-center justify-center mx-auto">
          <img src={Loader} alt="loader" />
        </div>

        <div className="mx-auto w-44">
          <LCButton
            isGray
            text="View account details"
            onClick={handleViewBankDetails || (() => {})}
          />
        </div>
        {canShowDetails && (
          <div className="space-y-5">
            <p className="w-full max-w-xs mx-auto text-xs text-center text-black-40">
              If it&apos;s taking too long, click{" "}
              <span
                className=""
                style={{
                  color: app.colour.dark,
                }}
              >
                close
              </span>{" "}
              below. You’ll receive value once we receive your payment
            </p>
            <button
              onClick={handleCloseTransactionClick}
              className="w-full text-sm font-medium outline-none"
              style={{
                color: app.colour.dark,
              }}
            >
              Close Modal
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentConfirmation;
