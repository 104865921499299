import React, { useContext, useState } from "react";
import { postVerifyID } from "../apis/services";
import { AppContext, appContextStore } from "../App";
import LCButton from "../components/LCButton";
import LCInput from "../components/LCInput";
import { ID_VERIFICATION, VERIFY_ID_VER_CODE } from "../constants/screen";
import { STORAGE_CHECKOUT_DETAILS } from "../constants/storage";
import LCNavbar from "../containers/LCNavbar";
import useTransactionRef from "../hooks/useTransactionRef";

const VerifyWithNIN = ({
  handleSubmittedNIN,
  handleReturnedPhoneNumber,
}: {
  handleSubmittedNIN: (v: string) => void;
  handleReturnedPhoneNumber: (v: string) => void;
}) => {
  const app = useContext(AppContext);
  const zusTransactionRef = useTransactionRef();

  const [nin, setNin] = useState("");
  const [isSubmittingNIN, setIsSubmittingNIN] = useState(false);

  const handleBack = () => {
    app.setCurrentScreen(ID_VERIFICATION);
  };

  const handleContinueClick = () => {
    setIsSubmittingNIN(true);
    postVerifyID(
      {
        type: "nin",
        verification_alert_type: "sms",
        id_number: nin,
      },
      zusTransactionRef
    )
      .then((res) => {
        handleSubmittedNIN(nin);
        handleReturnedPhoneNumber(res.ending_with);
        setIsSubmittingNIN(false);
        app.setCurrentScreen(VERIFY_ID_VER_CODE);
      })
      .catch((e) => {
        app.setErrorMessage(e?.msg);
        setIsSubmittingNIN(false);
      });
  };
  return (
    <div className="flex flex-col pb-4">
      <LCNavbar hasBack handleBackClick={handleBack} />
      <div className="flex flex-col w-full justify-between items-center">
        <div className="text-center w-full">
          <div className="space-y-2 mb-4">
            <h1 className="text-black text-lg font-semibold pb-1">
              Verify NIN
            </h1>
            <p className="text-xs grotesk py-2 px-3 w-full bg-[#FFF7EB] rounded-lg text-[#875100]">
              Please note: Your NIN is just used for verification
            </p>
            <p className="text-xs grotesk">
              Kindly provide your national identification number to continue
            </p>
          </div>
        </div>

        <form className="w-full">
          <div className="space-y-5 mb-14">
            <LCInput
              type="number"
              label="National Identification Number"
              placeholder="Enter NIN"
              value={nin}
              onChange={(e) => {
                setNin(e.target.value);
              }}
            />
          </div>
          <LCButton
            type="submit"
            isLoading={isSubmittingNIN}
            text="Continue"
            onClick={handleContinueClick}
          />
        </form>
      </div>
    </div>
  );
};

export default VerifyWithNIN;
