import React, { useContext, useEffect, useState } from "react";
import {
  getRequiresVerification,
  postDestinationAddress,
} from "../apis/services";
import { AppContext, TransactionContext } from "../App";
import LCButton from "../components/LCButton";
import LCInput from "../components/LCInput";
import LCInputWithLogo from "../components/LCInputWithLogo";
import LCTitleSubtitle from "../components/LCTitleSubtitle";
import {
  ID_VERIFICATION,
  SELECT_PAYMENT_METHOD,
  INITIATE_PURCHASE,
  SEND_TO_BANK_ACCOUNT,
} from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import useTransactionRef from "../hooks/useTransactionRef";
import { afterHyphen, before_, findCoinLogo } from "../utility";
import LCScreenLoader from "../components/LCScreenLoader";

const EnterWalletAddress = () => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);

  const zusTransactionRef = useTransactionRef();

  const isFieldFixed = (field: string) =>
    transaction.info.fixed_properties?.includes(field);
  const { submittedDetails, setSubmittedDetails } = transaction;

  const [walletAddress, setWalletAddress] = useState(
    transaction?.info?.destination_address ||
      submittedDetails?.destinationAddress
  );
  const [destinationTag, setDestinationTag] = useState(
    transaction?.info?.destination_tag || submittedDetails?.destinationTag
  );
  const [isSubmittingAddress, setIsSubmittingAddress] = useState(false);
  const [requiresVerification, setRequiresVerification] = useState(false);
  const [isRequiresVerificationLoading, setIsRequiresVerificationLoading] =
    useState(true);

  const [formError, setFormError] = useState({
    destinationTag: "",
    destinationAddress: "",
  });
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  useEffect(() => {
    if (
      transaction.info.state !== "initiated" ||
      transaction.submittedDetails?.bankAccountDetails
    ) {
      app.setCurrentScreen(SEND_TO_BANK_ACCOUNT);
    }
  }, []);

  useEffect(() => {
    getRequiresVerification(zusTransactionRef).then((res) => {
      if (transaction.info.is_destination_address_hidden) {
        if (res?.requires_verification) {
          app.setCurrentScreen(ID_VERIFICATION);
        } else {
          app.setCurrentScreen(SELECT_PAYMENT_METHOD);
        }
      }
      setRequiresVerification(res?.requires_verification);
      setIsRequiresVerificationLoading(false);
    });
  }, []);

  const handleNextStep = () => {
    if (requiresVerification) {
      app.setCurrentScreen(ID_VERIFICATION);
    } else {
      app.setCurrentScreen(SELECT_PAYMENT_METHOD);
    }
  };

  const isAddressValid = (address?: string) =>
    (address || walletAddress).match(
      new RegExp(submittedDetails?.currencyToGet?.address_regex, "gi")
    );

  const handleContinueClick = () => {
    if (isFieldFixed("destination_address")) {
      handleNextStep();
      return;
    }
    setHasAttemptedSubmit(true);
    if (isAddressValid()) {
      if (requiresTag() && !destinationTag.trim().length) {
        setFormError({
          ...formError,
          destinationTag: "Enter a valid destination tag",
        });
        return;
      }
      setIsSubmittingAddress(true);
      let detailsToSubmit = {
        ...transaction.submittedDetails,
        destinationAddress: walletAddress,
      };
      if (requiresTag()) {
        detailsToSubmit = {
          ...detailsToSubmit,
          destinationTag,
        };
      }
      setSubmittedDetails(detailsToSubmit);
      postDestinationAddress(
        {
          destination_address: walletAddress,
          destination_tag: requiresTag() ? destinationTag : null,
        },
        zusTransactionRef
      )
        .then((e) => {
          handleNextStep();
        })
        .catch((e) => {
          app.setErrorMessage(e?.msg);
          setIsSubmittingAddress(false);
        });
    } else {
      setFormError({
        ...formError,
        destinationAddress: "Enter a correct wallet address",
      });
    }
  };

  const handleBackClick = () => {
    app.setCurrentScreen(INITIATE_PURCHASE);
  };

  const selectedDestinationCurrency = () =>
    before_(submittedDetails?.currencyToGet?.coin);
  const selectedDestinationCurrencyNetwork = () =>
    afterHyphen(submittedDetails?.currencyToGet?.name);

  const requiresTag = () => submittedDetails?.currencyToGet?.requires_tag;

  const isRequiredFieldEmpty = () =>
    !walletAddress?.length ||
    (requiresTag() && !destinationTag?.trim()?.length);

  const validateEnteredAddress = (address: string) => {
    if (hasAttemptedSubmit) {
      if (isAddressValid(address)) {
        setFormError({
          ...formError,
          destinationAddress: "",
        });
      } else {
        setFormError({
          ...formError,
          destinationAddress: "Enter a correct wallet address",
        });
      }
    }
  };

  if (isRequiresVerificationLoading)
    return (
      <div>
        <LCScreenLoader info="Please hold on..." />
      </div>
    );

  return (
    <div className="flex flex-col">
      <LCNavbar hasBack handleBackClick={handleBackClick} />
      <div className="flex flex-col items-center justify-between w-full">
        <div className="w-full text-center">
          <LCTitleSubtitle
            title={`${
              isFieldFixed("destination_address") ? "Confirm" : "Enter"
            } ${selectedDestinationCurrency()} wallet address`}
            subtitle={`You need a ${selectedDestinationCurrency()} wallet to receive ${selectedDestinationCurrency()} on the ${selectedDestinationCurrencyNetwork()} network.`}
          />

          <form className="w-full pt-2 space-y-12">
            <div className="space-y-3 mb-14">
              <LCInputWithLogo
                logo={findCoinLogo(submittedDetails?.currencyToGet)}
                isDisabled={isFieldFixed("destination_address")}
                label={`${selectedDestinationCurrency()} Wallet Address`}
                placeholder={`Enter ${selectedDestinationCurrency()} address`}
                value={walletAddress}
                onChange={(e) => {
                  validateEnteredAddress(e.target.value);
                  setWalletAddress(e.target.value);
                }}
                error={formError.destinationAddress}
              />
              {requiresTag() ? (
                <LCInput
                  label="Destination Tag"
                  placeholder="Enter destination tag"
                  value={destinationTag}
                  isDisabled={isFieldFixed("destination_tag")}
                  onChange={(e) => {
                    setDestinationTag(e.target.value);
                  }}
                  error={formError.destinationTag}
                />
              ) : null}
            </div>
            <LCButton
              type="submit"
              isLoading={isSubmittingAddress || isRequiresVerificationLoading}
              isDisabled={isRequiredFieldEmpty()}
              text={!isRequiresVerificationLoading ? "Continue" : ""}
              onClick={handleContinueClick}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnterWalletAddress;
