import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getSupportedCurrencies } from "../apis/services";
import { AppContext, TransactionContext } from "../App";
import LCProcessStatus from "../components/LCProcessStatus";
import { ORDER_INFO } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";

const PaymentStatus = () => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);
  const [isSuccess, setIsSuccess] = useState(true);

  const {
    refetch: fetchCurrencies,
    data: supportedCurrencies,
    isFetching: isLoadingSupportedCurrencies,
    isError: supportedCurrenciesHasError,
  } = useQuery("supportedCurrencies", getSupportedCurrencies, {
    enabled: false,
  });

  useEffect(() => {
    fetchCurrencies();
  }, []);

  useEffect(() => {
    if (supportedCurrencies && !isLoadingSupportedCurrencies) {
      const p = supportedCurrencies?.receiver_currency.filter(
        ({ coin }: { coin: string }) =>
          coin === transaction?.info.receiver_currency
      );
      transaction.setSubmittedDetails({
        ...transaction.submittedDetails,
        currencyToGet: p[0],
      });
      const dumWait = setTimeout(() => {
        app.setCurrentScreen(ORDER_INFO);
      }, 3000);
      return () => {
        clearInterval(dumWait);
      };
    }
  }, [supportedCurrencies]);

  return (
    <div className="flex flex-col">
      <LCNavbar />
      <div className="mt-12">
        <LCProcessStatus
          isSuccess={isSuccess}
          successTitle="Payment Received"
          failTitle="Verification Failed"
          successActionComponent={<></>}
          failActionComponent={
            <button>
              <div
                className="flex items-center justify-center space-x-1"
                style={{
                  color: app.colour.dark,
                }}
              >
                <i className="ri-restart-line"></i>
                <p className="text-sm font-semibold">Retry verification</p>
              </div>
            </button>
          }
          successSubtitle={<>You&apos;ll be redirected shortly...</>}
          failSubtitle="We couldn’t verify you. Kindly check your details and retry."
        />
      </div>
    </div>
  );
};

export default PaymentStatus;
