import React, { ChangeEventHandler, ReactNode, useContext } from "react";
import { getCountryPhoneCode } from "../utility";
import { TransactionContext } from "../App";

export interface ILCInputPropType {
  type?: "tel" | "text" | "email" | "url" | "amount" | "number";
  placeholder?: string;
  label: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  extension?: ReactNode;
  error?: string;
  isDisabled?: boolean;
}
const LCInput = ({
  label,
  type = "text",
  value,
  onChange,
  placeholder,
  isDisabled,
  error,
  extension,
}: ILCInputPropType) => {
  const { info } = useContext(TransactionContext);
  return (
    <div className="w-full">
      <div className="flex flex-col items-start space-y-1">
        <p className="text-xs font-medium text-black-40">{label}</p>
        <div
          className={`border-b ${
            error?.trim().length ? "border-b-red" : "border-b-black-20"
          } w-full text-base font-medium pb-1  ${
            type === "tel" || type === "amount" ? "flex space-x-3" : ""
          }`}
        >
          {type === "tel" && (
            <p className="text-black-40">
              {getCountryPhoneCode(info?.country_code)}
            </p>
          )}
          <input
            disabled={isDisabled}
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className="w-full text-sm font-semibold outline-none"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
          />
          <div className="min-w-[24px]">{extension}</div>
        </div>
      </div>
      {!!error?.trim().length && (
        <p className="mt-1 text-xs text-left text-red">{error}</p>
      )}
    </div>
  );
};

export default LCInput;
