import React from "react";
import LoaderIcon from "../assets/icons/others/loader.svg";

const LCScreenLoader = (props: { info?: string }) => {
  return (
    <div className="bg-white h-[280px] flex flex-col items-center justify-center">
      <div className="flex items-center justify-center">
        <div className="animate-spin w-14 h-14 mx-auto">
          <img src={LoaderIcon} alt="loader" />
        </div>
      </div>
      {props.info ? (
        <div className="w-full mt-6">
          <p className="text-center text-base font-semibold">{props.info}</p>
        </div>
      ) : undefined}
    </div>
  );
};

export default LCScreenLoader;
