import React, { useEffect, useState } from "react";
import { appContextStore } from "../App";
import { STORAGE_CHECKOUT_DETAILS } from "../constants/storage";
import {
  getFromStorage,
  getJsonFromStorage,
  hasStorageAccess,
} from "../utility/storage";

const useTransactionRef = (isFetchingCheckoutDetails?: boolean) => {
  const transactionRef = appContextStore(
    (state) => state[STORAGE_CHECKOUT_DETAILS]?.reference
  );
  const generateTransactionRef = () => {
    return hasStorageAccess
      ? getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference
      : transactionRef;
  };
  const [transRef, setTransRef] = useState(generateTransactionRef());

  useEffect(() => {
    setTransRef(generateTransactionRef());
  }, [isFetchingCheckoutDetails]);

  return transRef;
};

export default useTransactionRef;
