export const BASE_URL = process.env.REACT_APP_REACT_APP_BACKEND_URL;

export const ENDPOINTS = {
  SUPPORTED_CURRENCIES: "/currencies",
  INITIATE_TRANSACTION: "/initiate",
  CHECKOUT_DETAILS: "/checkout-details",
  QUOTE: "/quote",
  LIMITS: "/limits",
  UPDATE_AMOUNT: "/currency",
  TRANSACTION_INFO: "/info",
  SET_ADDRESS: "/address",
  PAYMENT_METHODS: "/payment-methods",
  PAY_NOW: "/paynow",
  SET_EMAIL: "/email",
  SET_EMAIL_OTP: "/email/otp",
  EMAIL_OTP_CONFIRM: "/email/otp/confirm",
  SIGNIN_WITH_TOKEN: "/auth-token-login",
  REQUIRES_VERIFICATION: "/require-extra-verification",
  IDENTITY_VERIFICATION: "/identity-verify",
  COMPLETE_IDENTITY_VERIFICATION: "/identity-verify/otp",
  VERIFICATION_TYPES: "/identity-verify/methods",
  MOBILE_MONEY: "/mobile-money",
  TRANSACTION_HISTORY: "/history",
  MOMO_OTP: "/submit-momo-otp"
};
