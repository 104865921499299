import React from "react";
import Success from "../assets/icons/others/checkedSuccess.svg";
import Fail from "../assets/icons/others/errorMark.svg";

const LCProcessStatus = ({
  isSuccess,
  successTitle,
  failTitle,
  successSubtitle,
  failSubtitle,
  successActionComponent,
  failActionComponent,
}: {
  isSuccess: boolean;
  successTitle: string;
  failTitle: string;
  successSubtitle: string | JSX.Element;
  failSubtitle: string | JSX.Element;
  successActionComponent: JSX.Element;
  failActionComponent: JSX.Element;
}) => {
  return (
    <div className="space-y-5">
      <div className="w-20 h-20 mx-auto">
        <img src={isSuccess ? Success : Fail} alt="status" />
      </div>
      <div className="text-center space-y-2">
        <h1 className="font-semibold text-lg">
          {isSuccess ? successTitle : failTitle}
        </h1>
        <p className="text-sm w-full mx-auto max-w-[284px]">
          {isSuccess ? successSubtitle : failSubtitle}
        </p>
      </div>
      <div className="flex items-center justify-center w-full">
        {isSuccess ? successActionComponent : failActionComponent}
      </div>
    </div>
  );
};

export default LCProcessStatus;
