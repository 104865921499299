export const INITIATE_PURCHASE = "initiate_purchase";
export const ENTER_EMAIL = "enter_email";
export const ENTER_VERIFICATION_CODE = "enter_verification_code";
export const ENTER_WALLET_ADDRESS = "enter_wallet_address";
export const SELECT_PAYMENT_METHOD = "select_payment_method";
export const SEND_TO_BANK_ACCOUNT = "send_to_bank_account";
export const SEND_WITH_MOMO = "send_with_momo";
export const WAIT_FOR_MOMO = "wait_for_momo";
export const MOMO_OTP = "momo_otp";
export const ORDER_INFO = "order_info";
export const TRANSACTION_HISTORY = "transaction_history";

export const ID_VERIFICATION = "id_verification";
export const VERIFY_WITH_BVN = "verify_with_bvn";
export const VERIFY_ID_VER_CODE = "verify_id_ver_code";
export const VERIFY_WITH_NIN = "verify_with_nin";
export const VERIFY_WITH_GOV_ISSUED_ID = "verify_with_gov_issued_id";
export const VERIFICATION_STATUS = "verification_status";
export const PAYMENT_STATUS = "payment_status";

export const TRANSACTION_WARNING = "transaction_warning";
export const APP_ERROR = "app_error";
