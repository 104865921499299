import React, { useContext } from "react";
import LCNavbar from "../containers/LCNavbar";
import Warning from "../assets/icons/others/warningAlert.svg";
import { AppContext } from "../App";

const AppError = () => {
  const app = useContext(AppContext);
  return (
    <div>
      <div className="flex flex-col">
        <LCNavbar />
        <div>
          <div className="space-y-6">
            <div className="w-20 h-20 mx-auto">
              <img src={Warning} alt="warning alert" />
            </div>
            <div className="text-center space-y-2">
              <h1 className="font-semibold text-xl text-black">
                Hmm, something went wrong
              </h1>
              <p className="w-full mx-auto max-w-[284px]">
                Click the reload button below.
              </p>
            </div>
            <button
              onClick={() => {
                window.location.reload();
              }}
              className="flex items-center justify-center space-x-2 outline-none w-full"
              style={{
                color: app.colour.dark,
              }}
            >
              <i className="ri-restart-line ri-lg"></i>
              <p className="text-base font-medium">Reload</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppError;
