import chroma from "chroma-js";
import React, { useContext, useEffect, useState } from "react";
import { getPaymentMethods, postPayNow } from "../apis/services";
import { AppContext, TransactionContext } from "../App";
import {
  LCClickableList,
  LCClickableListItem,
} from "../components/LCClickableList";
import LCScreenLoader from "../components/LCScreenLoader";
import LCTitleSubtitle from "../components/LCTitleSubtitle";
import { SEND_TO_BANK_ACCOUNT, SEND_WITH_MOMO } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import useTransactionRef from "../hooks/useTransactionRef";

const paymentIconMap: any = {
  instant_p2p: "ri-user-shared-2-line",
};

const SelectPaymentMethod = () => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);

  const zusTransactionRef = useTransactionRef();

  const [paymentMethods, setPaymentMethods] = useState<
    { name: string; id: string }[]
  >([]);
  const [isFetchingSelectedPaymentMethod, setIsFetchingSelectedPaymentMethod] =
    useState(false);

  const [isFetchingMethods, setIsFetchingMethods] = useState(true);

  useEffect(() => {
    getPaymentMethods({
      currency:
        transaction?.info.sender_currency ||
        transaction.submittedDetails.currencyToPay?.fiat,
      country_code:
        transaction?.info.country_code ||
        transaction.submittedDetails.currencyToPay?.country_code,
      reference: zusTransactionRef,
    })
      .then((res) => {
        setPaymentMethods(res?.payment_methods);
        setIsFetchingMethods(false);
      })
      .catch((e) => {
        app.setErrorMessage(e?.msg);
      });
  }, []);

  const handlePaymentMethodClick = (id: string) => {
    setIsFetchingSelectedPaymentMethod(true);
    if (id.startsWith("mobile_money")) {
      transaction.setSubmittedDetails({
        ...transaction.submittedDetails,
        momo_id: id,
      });
      app.setCurrentScreen(SEND_WITH_MOMO);
    } else {
      postPayNow({ payment_method_id: id }, zusTransactionRef)
        .then((data) => {
          transaction.setSubmittedDetails({
            ...transaction.submittedDetails,
            bankAccountDetails: data,
          });
          if (data.bank_name) {
            app.setCurrentScreen(SEND_TO_BANK_ACCOUNT);
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch(() => {
          setIsFetchingSelectedPaymentMethod(false);
        });
    }
  };

  return (
    <div className="flex flex-col">
      <LCNavbar />
      {!isFetchingSelectedPaymentMethod ? (
        <div className="flex flex-col items-center justify-between w-full">
          <div className="w-full text-center">
            <LCTitleSubtitle
              title="How would you like to pay?"
              subtitle="Kindly select a payment method to proceed"
            />
            {!isFetchingMethods ? (
              <div>
                <LCClickableList>
                  {paymentMethods.map((method, i) => (
                    <LCClickableListItem
                      key={i}
                      icon={paymentIconMap[method.id]}
                      title={method.name}
                      extra={
                        <p
                          className="text-[10px]"
                          style={{
                            color: chroma(app.colour.darker).alpha(0.6).hex(),
                          }}
                        >
                          Est time: 5 mins
                        </p>
                      }
                      handleClick={() => {
                        handlePaymentMethodClick(method.id);
                      }}
                    />
                  ))}
                  {/* <LCClickableListItem
                  isDisabled
                  icon="ri-bank-line"
                  title="Bank Transfer"
                  extra={
                    <p className="text-sm bg-[#E7E7FF] px-2 py-1 rounded-[4px] text-purple-darker">
                      Coming Soon
                    </p>
                  }
                  handleClick={() => {}}
                /> */}
                </LCClickableList>
              </div>
            ) : (
              <div>
                <div
                  className="flex items-center justify-between w-full p-4 border-b h-14 animate-pulse border-black-10"
                  style={{
                    backgroundColor: app.colour.light,
                  }}
                >
                  <div
                    className="h-4 rounded-lg w-36"
                    style={{
                      backgroundColor: chroma(app.colour.default)
                        .alpha(0.1)
                        .hex(),
                    }}
                  ></div>
                  <div
                    className="w-24 h-4 rounded-lg"
                    style={{
                      backgroundColor: chroma(app.colour.default)
                        .alpha(0.1)
                        .hex(),
                    }}
                  ></div>
                </div>
                <div
                  className="flex items-center justify-between w-full p-4 border-b h-14 animate-pulse border-black-10"
                  style={{
                    backgroundColor: app.colour.light,
                  }}
                >
                  <div
                    className="h-4 rounded-lg w-36"
                    style={{
                      backgroundColor: chroma(app.colour.default)
                        .alpha(0.1)
                        .hex(),
                    }}
                  ></div>
                  <div
                    className="w-24 h-4 rounded-lg"
                    style={{
                      backgroundColor: chroma(app.colour.default)
                        .alpha(0.1)
                        .hex(),
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <LCScreenLoader info="Just a second..." />
      )}
    </div>
  );
};

export default SelectPaymentMethod;
