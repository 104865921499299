import React, { useContext, useState } from "react";
import { AppContext } from "../App";

const LCBurgerMenu = ({ handleClick }: { handleClick: () => void }) => {
  const app = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
    handleClick();
  };
  return (
    <button onClick={handleToggle} className="w-5 h-5">
      {app.isMenuOpen ? (
        <i className="ri-close-fill ri-lg" />
      ) : (
        <i className="ri-menu-fill ri-lg" />
      )}
    </button>
  );
};

export default LCBurgerMenu;
