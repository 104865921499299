import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  getLimit,
  getQuote,
  getSupportedCurrencies,
  updateAmount,
} from "../apis/services";

import { AppContext, TransactionContext } from "../App";
import LCButton from "../components/LCButton";
import LCInputAmountDropdown from "../components/LCInputAmountDropdown";
import LCScreenLoader from "../components/LCScreenLoader";
import LCSummary from "../components/LCSummary";
import {
  CRYPTO_TOKEN_NOT_SUPPORTED,
  FIAT_NOT_SUPPORTED,
} from "../constants/errorIDs";
import {
  APP_ERROR,
  ENTER_EMAIL,
  ORDER_INFO,
  SEND_TO_BANK_ACCOUNT,
  WAIT_FOR_MOMO,
} from "../constants/screen";
import {
  REFRESH_LIMIT_S,
  REFRESH_QUOTES_S,
  TRANSACTION_STATE,
} from "../constants/transaction";
import LCNavbar from "../containers/LCNavbar";
import useTransactionRef from "../hooks/useTransactionRef";
import {
  before_,
  calculateReceiverAmount,
  calculateSenderAmount,
  formatProcessorFee,
} from "../utility";

const InitiatePurchase = () => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);
  const isFieldFixed = (field: string) =>
    transactionInfo.fixed_properties?.includes(field);

  const {
    info: transactionInfo,
    setSubmittedDetails,
    submittedDetails,
  } = transaction;
  const zusTransactionRef = useTransactionRef();

  useEffect(() => {
    if (
      transaction.info.state !== "initiated" ||
      transaction.submittedDetails.bankAccountDetails
    ) {
      app.setCurrentScreen(ENTER_EMAIL);
    }
  }, []);

  useEffect(() => {
    if (
      transaction?.info.state === TRANSACTION_STATE.PENDING &&
      transaction?.info?.payment_method_id === "mobile_money"
    ) {
      return app.setCurrentScreen(WAIT_FOR_MOMO);
    }
    if (
      transaction?.info.state === TRANSACTION_STATE.PENDING &&
      transaction?.info?.payment_method_id === "bank_account"
    ) {
      return app.setCurrentScreen(SEND_TO_BANK_ACCOUNT);
    }
    if (transaction?.info.state === TRANSACTION_STATE.SENT_CRYPTO) {
      return app.setCurrentScreen(ORDER_INFO);
    }
    if (
      transaction?.info.state !== TRANSACTION_STATE.INITIATED &&
      transaction?.info.state !== TRANSACTION_STATE.PENDING &&
      transaction?.info.state !== TRANSACTION_STATE.REJECTED
    ) {
      return app.setCurrentScreen(ORDER_INFO);
    }
  }, []);

  const {
    data: supportedCurrencies,
    isLoading: isLoadingSupportedCurrencies,
    isError: supportedCurrenciesHasError,
  } = useQuery("supportedCurrencies", getSupportedCurrencies, {});

  const [fieldError, setFieldError] = useState({ pay: false, get: false });
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [amountToPay, setAmountToPay] = useState(0);
  const [amountToGet, setAmountToGet] = useState(0);

  const [currencyToGet, setCurrencyToGet] = useState(
    transaction.submittedDetails?.currencyToGet || {
      address_regex:
        "^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[(bc1q)|(bc1p)][0-9A-Za-z]{37,62}$",
      balance: null,
      coin: "BTC_BTC",
      estimated_arrival_time: "60",
      integer_multiple: "0.00000001",
      is_stable: false,
      max_decimal: 8,
      memo_regex: "",
      name: "Bitcoin - Bitcoin",
      requires_tag: false,
      withdraw_fee: "0.0002",
      withdraw_max: "7500",
      withdraw_min: "0.001",
    }
  );
  const [currencyToPay, setCurrencyToPay] = useState(
    transaction.submittedDetails?.currencyToPay || {
      fiat: "NGN",
      country_code: "NG",
      name: "Naira",
      max_decimal: 2,
    }
  );

  const [lastActiveField, setLastActiveField] = useState<any>({});

  const {
    data: quoteData,
    isLoading: isFetchQuoteLoading,
    isRefetching: isRefetchingQuote,
    isError: quoteDataHasError,
    error: quoteError,
  } = useQuery(
    ["quoteData", currencyToPay, currencyToGet],
    () =>
      getQuote({
        senderCurrency: currencyToPay?.fiat,
        receiverCurrency: currencyToGet?.coin,
        countryCode: currencyToPay?.country_code,
      }),
    {
      enabled: !isLoadingSupportedCurrencies,
      refetchInterval: REFRESH_QUOTES_S * 1000,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      retry(failureCount, error: any) {
        if (
          error?.errors?.includes(CRYPTO_TOKEN_NOT_SUPPORTED) ||
          error?.errors?.includes(FIAT_NOT_SUPPORTED)
        ) {
          return false;
        } else {
          return failureCount <= 3;
        }
      },
    }
  );

  const {
    data: limitsData,
    isLoading: isLimitsDataLoading,
    isError: limitsDataHasError,
  } = useQuery(
    ["limitsData", currencyToPay, currencyToGet],
    () =>
      getLimit({
        senderCurrency: currencyToPay?.fiat,
        receiverCurrency: currencyToGet?.coin,
      }),
    {
      enabled: !isLoadingSupportedCurrencies,
      refetchInterval: REFRESH_LIMIT_S * 1000,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
    }
  );

  // Fill currencyToGet if receiver_currency is present
  useEffect(() => {
    if (!!transactionInfo?.receiver_currency && !!supportedCurrencies) {
      const p = supportedCurrencies?.receiver_currency.filter(
        ({ coin }: { coin: string }) => {
          return coin === transactionInfo.receiver_currency;
        }
      );
      setCurrencyToGet(p[0]);
    }
    if (!!transactionInfo?.sender_currency && !!supportedCurrencies) {
      const p = supportedCurrencies?.sender_currency.find(
        ({ fiat, country_code }: { fiat: string; country_code: string }) => {
          return (
            fiat === transactionInfo.sender_currency &&
            country_code === transactionInfo.country_code
          );
        }
      );
      setCurrencyToPay(p);
    }
  }, [transactionInfo, supportedCurrencies]);

  useEffect(() => {
    if (
      !limitsData ||
      isFetchQuoteLoading ||
      isFieldFixed("sender_amount") ||
      isFieldFixed("receiver_amount")
    ) {
      return;
    }
    if (
      Number(Math.max(limitsData?.sender_minimum || 0, amountToPay)) !=
      Number(amountToPay)
    ) {
      setLastActiveField({});
    }
  }, [limitsData, isFetchQuoteLoading, quoteData, transactionInfo]);

  useEffect(() => {
    if (!quoteData || !transactionInfo) return;

    if (transactionInfo?.receiver_amount) {
      setAmountToGet(transactionInfo?.receiver_amount);
      handleAmountToGetChange(transactionInfo?.receiver_amount);
      return;
    }
    if (transactionInfo?.sender_amount) {
      setAmountToPay(transactionInfo?.sender_amount);
      handleAmountToPayChange(transactionInfo?.sender_amount);
    } else {
      setAmountToPay(limitsData?.sender_minimum);
      handleAmountToPayChange(limitsData?.sender_minimum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionInfo, quoteData, limitsData]);

  const validateMinMax = (callback: (x: boolean) => void) => {
    if (isFieldFixed("sender_amount") || isFieldFixed("receiver_amount")) {
      callback(false);
      return;
    }
    const hasFormError = { pay: false, get: false };
    if (lastActiveField?.sender_amount) {
      if (
        limitsData?.sender_minimum > amountToPay ||
        limitsData?.sender_maximum < amountToPay
      ) {
        hasFormError.pay = true;
      }
    } else {
      if (
        limitsData?.receiver_minimum > amountToGet ||
        limitsData?.receiver_maximum < amountToGet
      ) {
        hasFormError.get = true;
      }
    }
    setFieldError(hasFormError);
    callback(hasFormError.pay || hasFormError.get);
  };

  useEffect(() => {
    if (limitsData && hasAttemptedSubmit) {
      validateMinMax(() => {});
    }
  }, [amountToGet, amountToPay, limitsData]);

  // TEST AGAIN
  const handleCurrencyToPayChange = (value: any) => {
    setCurrencyToPay(value);
  };

  // TEST AGAIN
  const handleCurrencyToGetChange = (value: any) => {
    setCurrencyToGet(value);
    handleAmountToGetChange(
      String(Math.max(limitsData?.receiver_minimum || 0, amountToGet))
    );
  };

  const handleAmountToPayChange = (value: string | undefined) => {
    if (!quoteData) return;

    setLastActiveField({
      sender_amount: Number(value).toFixed(2),
      sender_currency: currencyToPay?.fiat,
      country_code: currencyToPay?.country_code,
      receiver_currency: currencyToGet?.coin,
    });
    setAmountToPay(Number(value) || 0);
    setAmountToGet(
      calculateReceiverAmount({
        senderAmount: Number(value),
        processorFee: formatProcessorFee(
          quoteData?.rate?.processor_fee,
          Number(value)
        ),
        exchangeRate: Number(quoteData?.rate.exchange_rate),
        networkFee: Number(quoteData?.rate.network_fee),
        dpGet: currencyToGet?.max_decimal,
        dpPay: currencyToPay?.max_decimal,
      }) || 0
    );
  };

  const handleAmountToGetChange = (value: string | undefined) => {
    if (!quoteData) return;

    setLastActiveField({
      receiver_amount: value,
      sender_currency: currencyToPay?.fiat,
      country_code: currencyToPay?.country_code,
      receiver_currency: currencyToGet?.coin,
    });
    setAmountToGet(Number(value) || 0);
    setAmountToPay(
      calculateSenderAmount({
        receiverAmount: Number(value),
        processorFee: quoteData?.rate?.processor_fee,
        exchangeRate: Number(quoteData?.rate.exchange_rate),
        networkFee: Number(quoteData?.rate.network_fee),
        dpGet: currencyToGet?.max_decimal,
        dpPay: currencyToPay?.max_decimal,
      }) || 0
    );
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const proceedToNextScreen = () => {
    app.setCurrentScreen(ENTER_EMAIL);
  };

  const handleContinueClick = () => {
    setHasAttemptedSubmit(true);
    setIsSubmitting(true);

    const saveFormInContext = () => {
      setSubmittedDetails({
        ...submittedDetails,
        amountToPay,
        amountToGet,
        currencyToPay,
        currencyToGet,
      });
    };

    validateMinMax((hasError: boolean) => {
      if (hasError) {
        setIsSubmitting(false);
        return;
      }
      saveFormInContext();
      const payloadToSubmit = { ...lastActiveField };
      if (
        (isFieldFixed("sender_amount") || isFieldFixed("receiver_amount")) &&
        isFieldFixed("sender_currency") &&
        isFieldFixed("receiver_currency")
      ) {
        proceedToNextScreen();
        setIsSubmitting(false);
        return;
      }
      if (isFieldFixed("receiver_currency")) {
        delete payloadToSubmit.receiver_currency;
      }
      if (isFieldFixed("sender_currency")) {
        delete payloadToSubmit.sender_currency;
      }
      if (isFieldFixed("country_code")) {
        delete payloadToSubmit.country_code;
      }
      updateAmount(payloadToSubmit, zusTransactionRef)
        ?.then(() => {
          proceedToNextScreen();
        })
        .catch((e) => {
          app.setErrorMessage(
            e?.msg || "Something went wrong, please try again"
          );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    });
  };

  // ERROR HANDLING IN PAGE
  useEffect(() => {
    if (
      supportedCurrenciesHasError ||
      quoteDataHasError ||
      limitsDataHasError
    ) {
      const typedQuoteError = quoteError as any;
      if (
        typedQuoteError?.errors?.includes(CRYPTO_TOKEN_NOT_SUPPORTED) &&
        typedQuoteError?.errors?.includes(FIAT_NOT_SUPPORTED)
      ) {
        setCurrencyToGet((supportedCurrencies?.sender_currency || [])[0]);
        setCurrencyToGet((supportedCurrencies?.receiver_currency || [])[0]);
        return;
      } else if (
        typedQuoteError?.errors?.includes(CRYPTO_TOKEN_NOT_SUPPORTED)
      ) {
        setCurrencyToGet((supportedCurrencies?.receiver_currency || [])[0]);
        return;
      } else if (typedQuoteError?.errors?.includes(FIAT_NOT_SUPPORTED)) {
        setCurrencyToGet((supportedCurrencies?.sender_currency || [])[0]);
        return;
      }
      app.setCurrentScreen(APP_ERROR);
    }
  }, [
    supportedCurrencies,
    supportedCurrenciesHasError,
    quoteDataHasError,
    quoteError,
    limitsDataHasError,
  ]);

  if (isFetchQuoteLoading || isFetchQuoteLoading) return <LCScreenLoader />;

  return (
    <div className="pb-3">
      <LCNavbar hasCompanyName />
      {!quoteData && !supportedCurrencies && !limitsData ? (
        <LCScreenLoader />
      ) : (
        <>
          <div className="mb-4">
            <div
              className={`flex flex-col w-full ${
                !app.isPopup ? "space-y-4" : "space-y-2"
              } items-center mb-2`}
            >
              <LCInputAmountDropdown
                label="You pay"
                value={String(Math.max(amountToPay, 0))}
                onChange={handleAmountToPayChange}
                onOptionChange={handleCurrencyToPayChange}
                selectedOption={currencyToPay}
                defaultOption={currencyToPay}
                dropDownData={supportedCurrencies?.sender_currency || []}
                placeholder={"0.0"}
                dropdownTitle={"Select Currency"}
                decimalsLimit={currencyToPay?.max_decimal || 0}
                decimalScale={2}
                isCurrencyLoading={isLoadingSupportedCurrencies}
                minValue={Number(limitsData?.sender_minimum || 0)}
                maxValue={Number(limitsData?.sender_maximum || 0)}
                hasError={fieldError.pay}
                isAllDisabled={
                  isFieldFixed("sender_amount") ||
                  isFieldFixed("receiver_amount") ||
                  supportedCurrenciesHasError
                }
                isDropdownDisabled={
                  isFieldFixed("sender_currency") ||
                  isFieldFixed("country_code")
                }
              />

              <LCInputAmountDropdown
                label="You get"
                isSearchable
                value={String(Math.max(amountToGet, 0))}
                onChange={handleAmountToGetChange}
                onOptionChange={handleCurrencyToGetChange}
                selectedOption={currencyToGet}
                defaultOption={currencyToGet}
                dropDownData={supportedCurrencies?.receiver_currency || []}
                placeholder={"0.0"}
                dropdownTitle={"Select Currency"}
                decimalsLimit={currencyToGet?.max_decimal || 0}
                isCurrencyLoading={isLoadingSupportedCurrencies}
                minValue={Number(limitsData?.receiver_minimum || 0)}
                maxValue={Number(limitsData?.receiver_maximum || 0)}
                hasError={fieldError.get}
                isAllDisabled={
                  isFieldFixed("sender_amount") ||
                  isFieldFixed("receiver_amount") ||
                  supportedCurrenciesHasError
                }
                isDropdownDisabled={isFieldFixed("receiver_currency")}
              />

              {((!isFetchQuoteLoading && quoteData?.rate) ||
                isRefetchingQuote) && (
                <LCSummary
                  amountToPay={Math.max(amountToPay, 0) || 0}
                  amountToGet={Math.max(amountToGet, 0) || 0}
                  currencyToPay={currencyToPay.fiat}
                  currencyToGet={before_(currencyToGet?.coin)}
                  shouldRefreshTimer={isRefetchingQuote}
                  quote={quoteData}
                />
              )}
            </div>
            <LCButton
              type="submit"
              text="Continue"
              onClick={handleContinueClick}
              isDisabled={
                isFetchQuoteLoading ||
                isLimitsDataLoading ||
                isLoadingSupportedCurrencies ||
                fieldError.get ||
                fieldError.pay ||
                supportedCurrenciesHasError
              }
              isLoading={isSubmitting}
            />
          </div>
          <p className="text-xs font-medium text-center text-black-40">
            By continuing you agree to our{" "}
            <a
              href="https://localramp.co/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              <span style={{ color: app.colour.dark }}> terms of use</span>
            </a>
          </p>
        </>
      )}
    </div>
  );
};

export default InitiatePurchase;
