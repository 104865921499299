import React, { ReactNode, useContext } from "react";
import { AppContext, appContextStore } from "../App";
import { STORAGE_CHECKOUT_DETAILS } from "../constants/storage";
import useTransactionRef from "../hooks/useTransactionRef";
import { sendMessageToParent } from "../utility";
import { getJsonFromStorage, hasStorageAccess } from "../utility/storage";
import LCButton from "./LCButton";

type LCWidgetContainerPropType = {
  transaction: any;
  children: ReactNode;
};
const LCWidgetContainer = ({
  transaction,
  children,
}: LCWidgetContainerPropType) => {
  const app = useContext(AppContext);

  const zusRef = useTransactionRef();

  const handleModalCloseClick = () => {
    if (!confirm("Are you sure you want to close this widget?")) return;
    if (app.isPopup) {
      sendMessageToParent({ key: "buy-closeModal" });
    } else {
      window.location = `${
        transaction?.callback_url ||
        transaction?.info?.callback_url ||
        "https://localramp.co"
      }?ref=${
        !hasStorageAccess
          ? zusRef
          : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference
      }` as string & Location;
    }
  };
  return (
    <div
      className={`w-full mb-4 sm:mb-0 ${
        app.isPopup ? "h-full" : "min-h-screen bg-white sm:bg-[#FAFAFA]"
      }`}
    >
      <div
        className={`${
          !app.isPopup
            ? "max-w-[356px] w-full sm:pt-32"
            : "max-w-[380px] w-full pt-6 sm:pt-32"
        } mx-auto relative`}
      >
        <div className="flex space-x-2 items-start">
          <div className="flex flex-col space-y-3 items-center w-full">
            <div
              className={`relative bg-white ${
                !app.isPopup
                  ? "w-full max-w-[344px] sm:border sm:border-[#E7E5E5] sm:rounded-2xl"
                  : "w-full max-w-[320px] border border-[#E7E5E5] rounded-2xl"
              } ${app.isPopup ? "min-h-[356px]" : "min-h-[452px]"} mx-auto`}
            >
              {app.errorMessage || app.toastMessage ? (
                <div
                  className="transition flex items-center justify-center absolute left-0 right-0"
                  style={{ zIndex: "60" }}
                >
                  <div className="inline-block mt-4 py-2 px-2 bg-white shadow-md rounded-md text-red/75">
                    <div className="flex items-center justify-between space-x-1">
                      {app.errorMessage ? (
                        <>
                          <i className="ri-error-warning-fill ri-xl"></i>
                          <p className="text-left font-medium text-sm">
                            {app.errorMessage}
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            className="text-left font-medium text-sm"
                            style={{ color: app.colour.dark }}
                          >
                            {app.toastMessage}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={`px-7 ${app.isPopup ? "py-4" : "py-7"} h-full`}>
                {children}
              </div>
            </div>
            <a href="https://localramp.co" target="_blank" rel="noreferrer">
              <p
                className={`text-sm font-semibold ${
                  app.isPopup ? "text-white" : "text-black"
                }`}
              >
                Powered by <span className="font-bold phonk">LocalRamp</span>
              </p>
            </a>
          </div>
          <div className="hidden sm:block">
            <button
              onClick={handleModalCloseClick}
              className="w-6 h-6 bg-[#FFFFFF8F] border border-[#E7E5E5] rounded-full flex items-center justify-center"
            >
              <i className="ri-close-fill ri-md" />
            </button>
          </div>
        </div>
      </div>
      <div className="w-44 mx-auto sm:hidden mt-4">
        <LCButton isGray text="Close Modal" onClick={handleModalCloseClick} />
      </div>
    </div>
  );
};

export default LCWidgetContainer;
