import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import { REFRESH_QUOTES_S } from "../constants/transaction";
import { useCountDown } from "../hooks/useCountdown";

const LCQuoteRefreshInfo = ({
  shouldRefreshTimer,
}: {
  shouldRefreshTimer: boolean;
}) => {
  const app = useContext(AppContext);
  const [counter, start, pause, reset] = useCountDown(REFRESH_QUOTES_S);
  useEffect(() => {
    start();
  }, [start]);

  useEffect(() => {
    if (shouldRefreshTimer) {
      reset();
    }
  }, [reset, shouldRefreshTimer]);

  return (
    <div
      className="flex items-center justify-end space-x-1 py-1"
      style={{
        color: app.colour.dark,
      }}
    >
      <i
        className={`ri-history-fill ri-1x ${
          shouldRefreshTimer && "animate-reverse-spin"
        }`}
        style={{
          color: app.colour.dark,
        }}
      ></i>
      <p
        className="text-xs font-medium"
        style={{
          color: app.colour.dark,
        }}
      >
        {shouldRefreshTimer
          ? `Fetching Quote...`
          : `Quote refreshes in ${counter}s`}
      </p>
    </div>
  );
};

export default LCQuoteRefreshInfo;
