import React, { useContext } from "react";
import { AppContext } from "../App";
import { copyToClipboard } from "../utility";

const LCAccountDetails = ({
  bankName,
  accountNumber,
  accountName,
  refCode,
}: {
  bankName: string;
  accountName: string;
  accountNumber: string;
  refCode: string;
}) => {
  const app = useContext(AppContext);
  return (
    <div
      className="rounded-lg text-center"
      style={{ backgroundColor: app.colour.light }}
    >
      <div className="p-4 border-b-[1.5px] border-black-10">
        <p className="text-sm font-bold mb-1">{bankName}</p>
        <button
          onClick={() => {
            copyToClipboard(accountNumber).then(() => {
              app.setToastMessage("Copied!");
            });
          }}
          className="flex justify-center items-center space-x-1 mb-2 mx-auto"
        >
          <p className="text-xl font-bold" style={{ color: app.colour.dark }}>
            {accountNumber}
          </p>
          <i className="ri-file-copy-fill ri-md text-black-40" />
        </button>
        <p className="text-xs text-black">{accountName}</p>
      </div>
      <div className="py-3 px-4 flex w-full justify-between items-center mb-[2px]">
        <p className="text-xs font-bold text-black">Reference (optional):</p>
        <button
          onClick={() => {
            copyToClipboard(refCode).then(() => {
              app.setToastMessage("Copied!");
            });
          }}
          className="flex justify-center items-center space-x-1 ml-auto"
        >
          <p className="text-xs font-semibold text-black-80">{refCode}</p>
          <i className="ri-file-copy-fill ri-sm text-black-40" />
        </button>
      </div>
    </div>
  );
};

export default LCAccountDetails;
