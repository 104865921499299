import React, { useContext } from "react";
import { AppContext } from "../App";

const LCDescriptionItem = (props: {
  title: string;
  content: string | JSX.Element;
}) => {
  const app = useContext(AppContext);
  return (
    <div className="space-y-1 text-left">
      <p
        className="text-xs font-semibold"
        style={{ color: app.colour.default }}
      >
        {props.title}
      </p>
      <span className="text-xs font-bold block">{props.content}</span>
    </div>
  );
};

export default LCDescriptionItem;
