import chroma from "chroma-js";
import React, { useContext, useEffect, useState } from "react";
import { getVerificationMethods } from "../apis/services";
import { AppContext, TransactionContext } from "../App";
import {
  LCClickableList,
  LCClickableListItem,
} from "../components/LCClickableList";
import LCScreenLoader from "../components/LCScreenLoader";
import {
  ENTER_WALLET_ADDRESS,
  VERIFY_WITH_BVN,
  VERIFY_WITH_GOV_ISSUED_ID,
  VERIFY_WITH_NIN,
} from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";

const VERIFICATION_TYPES = {
  NIN: "nin",
  DOCUMENT: "document",
  BVN: "bvn",
};

const IDVerification = () => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);

  const handleBack = () => {
    app.setCurrentScreen(ENTER_WALLET_ADDRESS);
  };

  const [verificationMethods, setVerificationMethods] = useState<string[]>([]);
  const [isLoadingVerificationMethods, setIsLoadingVerificationMethods] =
    useState(true);

  useEffect(() => {
    getVerificationMethods({
      senderCurrency:
        transaction?.info.sender_currency ||
        transaction.submittedDetails.currencyToPay.fiat,
    })
      .then((res) => {
        setVerificationMethods(res?.supported_verification_types);
        setIsLoadingVerificationMethods(false);
      })
      .catch((e) => {
        app.setErrorMessage(e?.msg);
      });
  }, []);

  return (
    <div className="flex flex-col">
      <LCNavbar hasBack handleBackClick={handleBack} />
      <div className="flex flex-col w-full justify-between items-center">
        <div className="text-center w-full pb-16">
          <div className="space-y-2 mb-4">
            <h1 className="text-black text-lg font-semibold pb-1">
              ID Verification
            </h1>
            <p className="text-xs py-2 px-3 w-full bg-[#FFF7EB] rounded-lg text-[#875100]">
              Purchase of high amounts requires verification
            </p>
            <p className="text-xs grotesk">
              This is a one-off process that will allow you to skip the
              verification step on any app that supports LocalRamp.
            </p>
          </div>
          {!isLoadingVerificationMethods ? (
            <LCClickableList>
              {verificationMethods.includes(VERIFICATION_TYPES.BVN) ? (
                <LCClickableListItem
                  icon="ri-bank-line"
                  title="Verify with BVN"
                  handleClick={() => {
                    app.setCurrentScreen(VERIFY_WITH_BVN);
                  }}
                />
              ) : null}
              {verificationMethods.includes(VERIFICATION_TYPES.NIN) ? (
                <LCClickableListItem
                  icon="ri-profile-line"
                  title="Verify with NIN"
                  handleClick={() => {
                    app.setCurrentScreen(VERIFY_WITH_NIN);
                  }}
                />
              ) : null}
              {verificationMethods.includes(VERIFICATION_TYPES.DOCUMENT) ? (
                <LCClickableListItem
                  icon="ri-passport-line"
                  title="Provide government issued ID"
                  handleClick={() => {
                    app.setCurrentScreen(VERIFY_WITH_GOV_ISSUED_ID);
                  }}
                />
              ) : null}
            </LCClickableList>
          ) : (
            <div>
              <div
                className="h-14 w-full animate-pulse p-4 flex items-center justify-between border-b-2 border-black-40"
                style={{
                  backgroundColor: app.colour.light,
                }}
              >
                <div
                  className="h-5 w-36 animate-pulse rounded-lg"
                  style={{
                    backgroundColor: chroma(app.colour.light).darken(3).hex(),
                  }}
                ></div>
                <div
                  className="h-5 w-24 animate-pulse rounded-lg"
                  style={{
                    backgroundColor: chroma(app.colour.light).darken(3).hex(),
                  }}
                ></div>
              </div>
              <div
                className="h-14 w-full animate-pulse p-4 flex items-center justify-between"
                style={{
                  backgroundColor: app.colour.light,
                }}
              >
                <div
                  className="h-5 w-36 animate-pulse rounded-lg"
                  style={{
                    backgroundColor: chroma(app.colour.light).darken(3).hex(),
                  }}
                ></div>
                <div
                  className="h-5 w-24 animate-pulse rounded-lg"
                  style={{
                    backgroundColor: chroma(app.colour.light).darken(3).hex(),
                  }}
                ></div>
              </div>
            </div>
          )}
          {/* {!isLoadingVerificationMethods ? (
            <div className="flex items-center space-x-1 justify-center mt-5">
              <i className="ri-timer-2-line ri-lg text-orange"></i>
              <p className="text-sm text-black">
                Takes less than <span className="font-semibold">2 mins</span>{" "}
              </p>
            </div>
          ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default IDVerification;
