import React, { useContext, useEffect, useState } from "react";
import { AppContext, TransactionContext } from "../App";
import LCButton from "../components/LCButton";
import LCProcessStatus from "../components/LCProcessStatus";
import { ID_VERIFICATION, SELECT_PAYMENT_METHOD } from "../constants/screen";
import { DOCUMENT_VERIFICATION_STATE } from "./VerifyWithGovIssuedID";

const VerificationStatus = () => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (
      transaction?.info?.document_verification_state ===
      DOCUMENT_VERIFICATION_STATE.FAILED
    ) {
      setIsSuccess(false);
    } else if (
      transaction?.info?.document_verification_state ===
      DOCUMENT_VERIFICATION_STATE.PASSED
    ) {
      setIsSuccess(true);
    }
  }, [transaction]);

  useEffect(() => {
    let timeout: any;
    if (isSuccess) {
      timeout = setTimeout(() => {
        app.setCurrentScreen(SELECT_PAYMENT_METHOD);
      }, 2000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccess]);

  return (
    <div className="w-full h-[396px] flex flex-col items-center justify-center">
      <LCProcessStatus
        isSuccess={isSuccess}
        successTitle="Verification Successful"
        failTitle="Verification Failed"
        successActionComponent={
          <></>
          // <LCButton
          //   text="Continue with transaction"
          //   onClick={() => {
          //     app.setCurrentScreen(SELECT_PAYMENT_METHOD);
          //   }}
          // />
        }
        failActionComponent={
          <button
            onClick={() => {
              app.setCurrentScreen(ID_VERIFICATION);
            }}
          >
            <div
              className="flex items-center justify-center space-x-2"
              style={{
                color: app.colour.dark,
              }}
            >
              <i className="ri-restart-line"></i>
              <p className="font-semibold">Retry verification</p>
            </div>
          </button>
        }
        successSubtitle={<></>}
        failSubtitle="We couldn’t verify you. Kindly check your details and retry."
      />
    </div>
  );
};

export default VerificationStatus;
