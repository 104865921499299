/* eslint-env browser */
import chroma from "chroma-js";
import { create } from "zustand";
import React, { createContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { getCheckoutDetails, getTransactionInfo } from "./apis/services";
import LCScreenLoader from "./components/LCScreenLoader";
import LCWidgetContainer from "./components/LCWidgetContainer";
import { INITIATE_PURCHASE } from "./constants/screen";
import { STORAGE_CHECKOUT_DETAILS, STORAGE_TOKEN } from "./constants/storage";
import ScreenManager from "./screens";
import TransactionWarning from "./screens/TransactionWarning";
import "./styles/global.css";
import { sendMessageToParent } from "./utility";
import { clearFromSession, hasStorageAccess } from "./utility/storage";
import ErrorBoundary from "./screens/ErrorBoundary";
import { useLocation } from "react-router-dom";
import useTransactionRef from "./hooks/useTransactionRef";

const queryClient = new QueryClient();

type TState = {
  [STORAGE_CHECKOUT_DETAILS]: any;
  [STORAGE_TOKEN]: any;
  [x: string]: any;
};
type TAction = {
  setCheckoutDetails: (details: any) => void;
  setToken: (details: any) => void;
  reset: () => void;
};

export const appContextStore = create<TState & TAction>((set) => ({
  [STORAGE_CHECKOUT_DETAILS]: undefined,
  [STORAGE_TOKEN]: undefined,
  setToken: (x) => set(() => ({ [`${STORAGE_TOKEN}:${x.email}`]: x.value })),
  setCheckoutDetails: (x) => set(() => ({ [STORAGE_CHECKOUT_DETAILS]: x })),
  reset: () => set(() => ({})),
}));

export const AppContext = createContext<{
  errorMessage: string | undefined;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  toastMessage: string | undefined;
  setToastMessage: React.Dispatch<React.SetStateAction<string>>;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentScreen: string;
  setCurrentScreen: (v: string) => void;
  previousScreen: string;
  colour: {
    default: string;
    dark: string;
    darker: string;
    light: string;
    disabled: string;
  };
  isPopup: boolean;
}>({
  errorMessage: undefined,
  setErrorMessage: () => {},
  toastMessage: undefined,
  setToastMessage: () => {},
  isMenuOpen: false,
  setIsMenuOpen: () => {},
  currentScreen: INITIATE_PURCHASE,
  setCurrentScreen: () => {},
  previousScreen: "",
  colour: {
    default: "#5551FF",
    dark: "#3A37CD",
    darker: "#1C1A68",
    light: "#F4F3FF",
    disabled: chroma("#5551FF").alpha(0.7).hex(),
  },
  isPopup: false,
});

export const TransactionContext = createContext<{
  info: any;
  submittedDetails: any;
  setInfo: React.Dispatch<React.SetStateAction<any>>;
  setSubmittedDetails: React.Dispatch<React.SetStateAction<any>>;
}>({
  info: {},
  setInfo: () => {},
  submittedDetails: {},
  setSubmittedDetails: () => {},
});

const handleRetryInitialization = () => {
  sendMessageToParent({ key: "buy-retryInitialization" });
};

const App = () => {
  const [initializationError, setInitializationError] = useState<
    { error_id: string; msg: string; data: any; endpoint: string } | undefined
  >();
  const location = useLocation();
  const [checkoutLinkRef, setCheckoutLinkRef] = useState(
    location.pathname.substring(1)
  );

  const setZusCheckout = appContextStore((state) => state.setCheckoutDetails);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentScreen, setCurrentScreenO] = useState(INITIATE_PURCHASE);
  const [previousScreen, setPreviousScreen] = useState("");
  const setCurrentScreen = (value: string) => {
    setPreviousScreen(currentScreen);
    setCurrentScreenO(value);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const [appColour, setAppColour] = useState<{
    default: string;
    dark: string;
    darker: string;
    light: string;
    disabled: string;
  }>({
    default: "#5551FF",
    dark: "#3A37CD",
    darker: "#1C1A68",
    light: "#F4F3FF",
    disabled: chroma("#5551FF").alpha(0.7).hex(),
  });

  const [isPopup, setIsPopup] = useState(false);

  useEffect(() => {
    sendMessageToParent({ key: "buy-confirmIAmAPopup" });
  }, []);

  useEffect(() => {
    function getMessageFromParent(event: MessageEvent<any>) {
      //  const origin = event.origin;
      if (typeof event.data == "object") {
        if (event.data.nature === "popup") {
          if (!isPopup) {
            setIsPopup(true);
          }
        }
        if (event.data.call == "sendError") {
          setInitializationError(event.data.value);
        }
        if (event.data.call == "sendCheckoutRef") {
          setCheckoutLinkRef(event.data.value);
        }
      }
    }
    window.addEventListener("message", getMessageFromParent);
  }, [isPopup]);

  useEffect(() => {
    if (
      !errorMessage?.trim().length ||
      errorMessage === "Unable to fetch transaction, kindly refresh"
    )
      return;
    const timeout = setTimeout(() => {
      setErrorMessage("");
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [errorMessage]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setToastMessage("");
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [toastMessage]);

  const [submittedDetails, setSubmittedDetails] = useState({});

  const [transactionInfo, setTransactionInfo] = useState({});

  const [isFetchingCheckoutDetails, setIsFetchingCheckoutDetails] =
    useState(true);
  const [isFetchingTransactionInfo, setIsFetchingTransactionInfo] =
    useState(true);

  const handleGetCheckoutDetails = () => {
    if (!navigator.onLine) return;
    if (checkoutLinkRef.length) {
      getCheckoutDetails({ linkRef: checkoutLinkRef }, setZusCheckout)
        .then(() => {
          setIsFetchingCheckoutDetails(false);
          setErrorMessage("");
        })
        .catch((e) => {
          setErrorMessage("Something went wrong, try again");
          setInitializationError(e);
        });
    }
  };

  const resetZus = appContextStore((state) => state.reset);
  const transactionRef = useTransactionRef(isFetchingCheckoutDetails);

  useEffect(() => {
    hasStorageAccess ? clearFromSession() : resetZus();
    if (!navigator.onLine) {
      setErrorMessage(
        "You're likely offline, click the refresh icon below to try again"
      );
      return;
    }
    handleGetCheckoutDetails();
  }, [navigator.onLine, checkoutLinkRef]);

  useEffect(() => {
    if (!checkoutLinkRef) {
      setInitializationError({
        error_id: "noLinkRef",
        msg: "Enter a valid reference",
        data: null,
        endpoint: "/",
      });
      return;
    }
    if (checkoutLinkRef && !isFetchingCheckoutDetails && transactionRef) {
      getTransactionInfo(transactionRef)
        .then((res) => {
          if (res) {
            setTransactionInfo(res);
            setIsFetchingTransactionInfo(false);
            setAppColour({
              default: res?.widget_color || "#5551FF",
              dark: res.widget_color
                ? chroma(res?.widget_color).darken(1.2).hex()
                : "#3A37CD",
              darker: res.widget_color
                ? chroma(res?.widget_color).darken(2).hex()
                : "#1C1A68",
              light: res.widget_color
                ? chroma(res?.widget_color).alpha(0.1).hex()
                : "#F4F3FF",
              disabled: chroma(res?.widget_color || "#5551FF")
                .alpha(0.7)
                .hex(),
            });
          }
        })
        .catch(() => {
          setErrorMessage("Unable to fetch transaction, kindly refresh");
        });
    }
  }, [isFetchingCheckoutDetails, checkoutLinkRef, transactionRef]);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider
        value={{
          isMenuOpen,
          errorMessage,
          toastMessage,
          setIsMenuOpen,
          setErrorMessage,
          setToastMessage,
          currentScreen,
          previousScreen,
          setCurrentScreen,
          colour: appColour,
          isPopup: isPopup,
        }}
      >
        <LCWidgetContainer transaction={transactionInfo}>
          <ErrorBoundary>
            {initializationError?.error_id ? (
              <TransactionWarning
                errorMessage={initializationError.msg}
                handleRetry={isPopup ? handleRetryInitialization : undefined}
              />
            ) : !navigator.onLine ? (
              <div className="bg-white h-[300px] flex flex-col items-center justify-center">
                <button onClick={handleGetCheckoutDetails}>
                  <div
                    className="flex flex-col items-center justify-center space-y-2"
                    style={{ color: appColour.dark }}
                  >
                    <i
                      className="p-3 rounded-full ri-restart-line ri-xl"
                      style={{
                        backgroundColor: chroma(appColour.dark)
                          .alpha(0.05)
                          .hex(),
                      }}
                    ></i>
                    <p className=" text-black-40">Refresh payment modal</p>
                  </div>
                </button>
              </div>
            ) : isFetchingCheckoutDetails || isFetchingTransactionInfo ? (
              <LCScreenLoader />
            ) : (
              <TransactionContext.Provider
                value={{
                  info: transactionInfo,
                  setInfo: setTransactionInfo,
                  submittedDetails,
                  setSubmittedDetails,
                }}
              >
                <ScreenManager />
              </TransactionContext.Provider>
            )}
          </ErrorBoundary>
        </LCWidgetContainer>
      </AppContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
