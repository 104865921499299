/* eslint-disable import/no-unresolved */
import { useEffect, useState } from "react";
import { before_, findCoinLogo, getLogoFor } from "../utility";
import LCDropdownList from "./LCDropdownList";

import LCDropdownListSearchable from "../containers/LCDropdownListSearchable";

export type DropDownDatatype = {
  id?: number;
  name: string;
  coin: string;
  fiat?: string;
  country_code?: string;
};

const LCDropdown = ({
  dropdownTitle,
  options,
  defaultOption,
  onOptionChange,
  isDisabled,
  isSearchable = false,
}: {
  dropdownTitle: string;
  defaultOption: DropDownDatatype;
  options: DropDownDatatype[];
  isSearchable?: boolean;
  onOptionChange: (v: DropDownDatatype) => void;
  isDisabled: boolean;
}) => {
  const [shouldShowDropdownList, setShouldShowDropdownList] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption); //This should come from the top

  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [defaultOption]);

  const toggleDropdownList = () => {
    setShouldShowDropdownList(!shouldShowDropdownList);
  };
  const handleOptionSelect = (v: DropDownDatatype) => {
    if (isDisabled) {
      return;
    }
    onOptionChange(v);
    setSelectedOption(v);
    setShouldShowDropdownList(false);
  };

  return (
    <>
      <button
        className="flex items-center space-x-0 cursor-pointer"
        onClick={toggleDropdownList}
        disabled={isDisabled}
      >
        <div className="flex items-center space-x-1">
          <div
            className={`w-4 h-4 ${
              !findCoinLogo(selectedOption) && "bg-black-20"
            } rounded-full`}
          >
            <img
              src={
                selectedOption?.country_code
                  ? getLogoFor(selectedOption?.country_code)
                  : findCoinLogo(selectedOption)
              }
              alt="currency"
              className="rounded-full"
            />
          </div>
          <p className="text-xs font-semibold uppercase text-black-80">
            {before_(selectedOption?.fiat || selectedOption?.coin || "")}
          </p>
        </div>
        {isDisabled ? null : <i className="ri-arrow-down-s-fill"></i>}
      </button>
      {shouldShowDropdownList &&
        (isSearchable ? (
          <LCDropdownListSearchable
            searchLabel="Coin search"
            searchPlaceholder="Enter coin name"
            allOptions={options}
            onOptionSelect={handleOptionSelect}
            onClose={setShouldShowDropdownList}
          />
        ) : (
          <LCDropdownList
            options={options}
            title={dropdownTitle}
            onOptionSelect={handleOptionSelect}
            onClose={setShouldShowDropdownList}
          />
        ))}
    </>
  );
};

export default LCDropdown;
