import chroma from "chroma-js";
import React, { useContext, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { AppContext } from "../App";
import { before_, commalize } from "../utility";
import LCDropdown from "./LCDropdown";

const LCAmountRange = (props: {
  min: number;
  max: number;
  currency: string;
  decimalPoint: number;
}) => (
  <>
    {commalize(props.min, props.decimalPoint)} -{" "}
    {commalize(props.max, props.decimalPoint)} {props.currency}
  </>
);

const LCInputDropdown = (props: {
  label: string;
  value: string;
  placeholder: string;
  onChange: (x: string | undefined) => void;
  selectedOption: any;
  onOptionChange: (x: any) => void;
  dropDownData: any[];
  defaultOption: any;
  dropdownTitle: string;
  isCurrencyLoading: boolean;
  decimalsLimit: number;
  decimalScale?: number;
  maxValue?: number;
  minValue?: number;
  hasError: boolean;
  isAllDisabled?: boolean;
  isDropdownDisabled?: boolean;
  isSearchable?: boolean;
}) => {
  const app = useContext(AppContext);
  const [inValue, setInValue] = useState<string | number | undefined>(
    props.value
  );
  useEffect(() => {
    setInValue(props.value || 0);
  }, [props.value]);

  const [isInputFocus, setIsInputFocus] = useState(false);
  return (
    <div className="w-full">
      <label
        htmlFor={props.label}
        className="text-xs font-medium text-black-40"
      >
        {props.label}
      </label>
      <div className="flex items-center w-full pb-1 space-x-3 text-base font-semibold border-b outline-none border-b-black-20">
        {/* {props.minValue ? ( */}
        <CurrencyInput
          id={props.label}
          name={props.label}
          placeholder={props.placeholder}
          defaultValue={""}
          value={inValue}
          decimalsLimit={props.decimalsLimit}
          decimalScale={props.decimalScale}
          onValueChange={(value) => {
            if (Number(value) < 0) return;
            setInValue(value);
            props.onChange(value);
          }}
          disabled={props.isAllDisabled || props.isCurrencyLoading}
          onFocus={() => {
            setIsInputFocus(true);
          }}
          onBlur={() => {
            setIsInputFocus(false);
          }}
          className="w-full text-sm font-semibold outline-none"
        />
        {/* ) : (
          <div
            className="w-full h-4 rounded-2xl animate-pulse"
            style={{
              backgroundColor: chroma(app.colour.default).alpha(0.1).hex(),
            }}
          ></div>
        )} */}
        {!props.isCurrencyLoading && (
          <LCDropdown
            onOptionChange={props.onOptionChange}
            defaultOption={props.defaultOption}
            dropdownTitle={props.dropdownTitle}
            options={props.dropDownData}
            isDisabled={
              props.isAllDisabled ||
              props.isDropdownDisabled ||
              props.isCurrencyLoading
            }
            isSearchable={props.isSearchable}
          />
        )}
      </div>
      {/* {props.minValue ? ( */}
      <p
        className={`${
          props.hasError ? "text-red" : "text-gray-400"
        } text-xs mt-1`}
      >
        {props.hasError && "Amount should be between "}
        {(isInputFocus || props.hasError) && (
          <LCAmountRange
            min={props.minValue || 0}
            max={Number(props.maxValue)}
            currency={before_(
              props.selectedOption?.fiat || props.selectedOption?.coin
            )}
            decimalPoint={props.decimalsLimit}
          />
        )}
      </p>
      {/* ) : (
        <div
          className="w-16 h-2 mt-1 rounded-2xl animate-pulse"
          style={{
            backgroundColor: chroma(app.colour.default).alpha(0.1).hex(),
          }}
        ></div>
      )} */}
    </div>
  );
};

export default LCInputDropdown;
