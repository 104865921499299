import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { getTransactionHistory } from "../apis/services";
import { AppContext, appContextStore, TransactionContext } from "../App";
import LCBack from "../components/LCBack";
import LCScreenLoader from "../components/LCScreenLoader";
import LCTransactionListItem from "../components/LCTransactionListItem";
import { STORAGE_CHECKOUT_DETAILS, STORAGE_TOKEN } from "../constants/storage";
import TransactionWarning from "./TransactionWarning";

const TransactionHistory = ({
  handleHomeClick,
}: {
  handleHomeClick: () => void;
}) => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);

  const zusStore = appContextStore((state) => state);
  const zusToken = (email: string) => zusStore[`${STORAGE_TOKEN}:${email}`];

  const [isFetchingTransactionHistory, setIsFetchingTransactionHistory] =
    useState(true);
  const [fetchingError, setFetchingError] = useState();
  const [transactionHistory, setTransactionHistory] =
    useState<{ data: any[]; pagination: any }>();
  const availableEmail =
    transaction.info?.email || transaction.submittedDetails?.email;

  const initiateGetHistory = (
    email: string,
    params: { page: number; limit: number } = { page: 1, limit: 5 }
  ) => {
    setIsFetchingTransactionHistory(true);
    getTransactionHistory(email, params, zusToken(availableEmail))
      .then((res) => {
        setTransactionHistory(res);
      })
      .catch((e) => {
        app.setErrorMessage(e);
        setFetchingError(e?.msg);
      })
      .finally(() => {
        setIsFetchingTransactionHistory(false);
      });
  };

  useEffect(() => {
    initiateGetHistory(availableEmail, { page: 1, limit: 5 });
  }, []);

  useEffect(() => {
    app.setIsMenuOpen(false);
  }, []);
  const onPaginateChange = (query: any, pagination: any) => {
    if (pagination.pageNumber < 1) return;
    initiateGetHistory(availableEmail, {
      page: pagination.pageNumber,
      limit: 5,
    });
  };

  return (
    <div>
      <div className="py-2">
        <LCBack text="Transaction History" handleClick={handleHomeClick} />
      </div>
      <div>
        <div className="mb-4 h-[302px]">
          {isFetchingTransactionHistory ? (
            <LCScreenLoader />
          ) : fetchingError ? (
            <TransactionWarning
              inScreen
              errorMessage={fetchingError}
              handleRetry={() => {
                initiateGetHistory(
                  transaction.info?.email ||
                    transaction.submittedDetails?.email,
                  { page: 1, limit: 5 }
                );
              }}
            />
          ) : !transactionHistory?.data?.length ? (
            <div className="min-h-[260px] flex flex-col items-center justify-center">
              <p className="grotesk font-bold text-black-20">
                No transaction history
              </p>
            </div>
          ) : (
            <>
              <div className="h-[280px] overflow-y-scroll mb-5">
                {transactionHistory?.data?.map((record, i) => (
                  <LCTransactionListItem
                    key={i}
                    receiverCurrency={record?.receiver_currency}
                    createdAt={record?.created_at}
                    senderCurrency={record?.sender_currency}
                    sentAmount={record?.sent_amount}
                    receivedAmount={record?.received_amount}
                  />
                ))}
              </div>

              <div className="space-y-2">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<i className="ri-arrow-right-s-line ri-lg" />}
                  forcePage={transactionHistory?.pagination.page - 1}
                  onPageChange={(pn) => {
                    onPaginateChange({}, { pageNumber: pn.selected + 1 });
                  }}
                  pageRangeDisplayed={10}
                  pageCount={Math.ceil(
                    (transactionHistory.pagination?.count || 0) /
                      transactionHistory.pagination?.limit
                  )}
                  previousLabel={<i className="ri-arrow-left-s-line ri-lg" />}
                  nextClassName="lr-pagination-next"
                  previousClassName="lr-pagination-previous"
                  containerClassName="lr-pagination"
                  pageClassName="lr-pagination-item"
                  activeClassName="lr-pagination-item_active"
                  renderOnZeroPageCount={() => {}}
                />
                <p className="grotesk text-xs font-semibold">
                  Showing <b>{transactionHistory.data.length}</b> of{" "}
                  <b>{transactionHistory?.pagination.count}</b> entries
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
