import { api } from "../apis";
import { DropDownDatatype } from "../components/LCDropdown";

export const HIGHEST_AMOUNT_WO_VERIFICATION = 2400000;

// TIME in seconds
export const REFRESH_QUOTES_S = 120;
export const REFRESH_LIMIT_S = 120;
export const REFRESH_CONFIRM_PAYMENT_FREQ = 10;
export const REFRESH_CONFIRM_PAYMENT_DURATION = 3600;
export const CHECK_FOR_RECEIVED_AMOUNT_FREQ = 10;
export const CHECK_FOR_RECEIVED_AMOUNT_DURATION = 1800;
export const CHECK_FOR_DOCUMENT_STATUS_FREQ = 10;

export const TRANSACTION_STATE = {
  PENDING: "pending",
  INITIATED: "initiated",
  REJECTED: "rejected",
  SENT_CRYPTO: "sent_crypto",
  PAYMENT_RECEIVED: "payment_received",
  BOUGHT_CRYPTO: "bought_crypto",
  SENDING_CRYPTO: "sending_crypto",
  COMPLETED: "completed",
};

export const popularCoinsListFn = (options: DropDownDatatype[]) => {
  const POPULAR: { [x: string]: any } = {
    BTC_BTC: {
      coin: "BTC_BTC",
      name: "Bitcoin - Bitcoin",
      address_regex:
        "^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[(bc1q)|(bc1p)][0-9A-Za-z]{37,62}$",
      memo_regex: "",
      withdraw_min: "0.001",
      withdraw_max: "7500",
      withdraw_fee: "0.0002",
      requires_tag: false,
      balance: null,
      max_decimal: 8,
      integer_multiple: "0.00000001",
      is_stable: false,
      estimated_arrival_time: "60",
    },
    LTC_LTC: {
      coin: "LTC_LTC",
      name: "Litecoin - Litecoin",
      address_regex: "^(L|M)[A-Za-z0-9]{33}$|^(ltc1)[0-9A-Za-z]{39}$",
      memo_regex: "",
      withdraw_min: "0.002",
      withdraw_max: "10000000000",
      withdraw_fee: "0.001",
      requires_tag: false,
      balance: null,
      max_decimal: 8,
      integer_multiple: "0.00000001",
      is_stable: false,
      estimated_arrival_time: "10",
    },
    USDT_BSC: {
      coin: "USDT_BSC",
      name: "TetherUS - BNB Smart Chain (BEP20)",
      address_regex: "^(0x)[0-9A-Fa-f]{40}$",
      memo_regex: "",
      withdraw_min: "10",
      withdraw_max: "10000000000",
      withdraw_fee: "0.29",
      requires_tag: false,
      balance: null,
      max_decimal: 8,
      integer_multiple: "0.00000001",
      is_stable: false,
      estimated_arrival_time: "5",
    },
    ETH_ETH: {
      coin: "ETH_ETH",
      name: "Ethereum - Ethereum (ERC20)",
      address_regex: "^(0x)[0-9A-Fa-f]{40}$",
      memo_regex: "",
      withdraw_min: "0.0098",
      withdraw_max: "10000000000",
      withdraw_fee: "0.000768",
      requires_tag: false,
      balance: null,
      max_decimal: 8,
      integer_multiple: "0.00000001",
      is_stable: false,
      estimated_arrival_time: "5",
    },
  };
  let ENABLED_COINS: { [x: string]: boolean } = {};
  options.forEach(({ id, coin }) => {
    ENABLED_COINS[coin] = true;
  });
  let LIST: any[] = [];
  Object.entries(ENABLED_COINS).forEach(([key, value]) => {
    if (ENABLED_COINS[key] && POPULAR[key]) {
      LIST.push(POPULAR[key]);
    }
  });
  return LIST;
};
