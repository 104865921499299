import React, { useContext, useEffect, useState } from "react";
import {
  postConfirmEmailOTP,
  postEmailOTP,
  sendOTP,
  signInWithToken,
} from "../apis/services";
import { AppContext, appContextStore, TransactionContext } from "../App";
import LCButton from "../components/LCButton";
import LCInputPin from "../components/LCInputPin";
import LCScreenLoader from "../components/LCScreenLoader";
import {
  ENTER_EMAIL,
  ENTER_WALLET_ADDRESS,
  INITIATE_PURCHASE,
} from "../constants/screen";
import { STORAGE_CHECKOUT_DETAILS, STORAGE_TOKEN } from "../constants/storage";
import LCNavbar from "../containers/LCNavbar";
import useTransactionRef from "../hooks/useTransactionRef";
import { maskEmail } from "../utility";
import { getFromStorageLocal, hasStorageAccess } from "../utility/storage";
import { useCountDown } from "../hooks/useCountdown";
import { useMutation } from "react-query";

const EnterVerificationCode = ({
  emailSubmitted,
}: {
  emailSubmitted: string;
}) => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);
  const isFieldFixed = (field: string) =>
    transaction.info.fixed_properties?.includes(field);

  const zusTransactionRef = useTransactionRef();
  const setZusToken = appContextStore((state) => state.setToken);

  const zusStore = appContextStore((state) => state);
  const zusToken = (email: string) => zusStore[`${STORAGE_TOKEN}:${email}`];

  const [verificationCode, setVerificationCode] = useState("");
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const [isCodeResent, setIsCodeResent] = useState(false);
  const [isCheckingOTPLogin, setIsCheckingOTPLogin] = useState(true);

  useEffect(() => {
    if (
      transaction.info.state !== "initiated" ||
      transaction.info.authenticated ||
      transaction.submittedDetails.email ||
      transaction.submittedDetails.bankAccountDetails
    ) {
      if (app.previousScreen === ENTER_WALLET_ADDRESS) {
        app.setCurrentScreen(ENTER_EMAIL);
        return;
      } else {
        transaction.setSubmittedDetails({
          ...transaction.submittedDetails,
          email: emailSubmitted,
        });
        app.setCurrentScreen(ENTER_WALLET_ADDRESS);
      }
      return;
    } else {
      const email =
        transaction.info.email ||
        transaction.submittedDetails.email ||
        emailSubmitted;
      if (email) {
        const isEmailStored = hasStorageAccess
          ? getFromStorageLocal(`${STORAGE_TOKEN}:${email}`)
          : zusToken(email);
        if (isEmailStored) {
          signInWithToken(email, {
            reference: zusTransactionRef,
            user_token: zusToken(email),
          })
            .then((e) => {
              transaction.setSubmittedDetails({
                ...transaction.submittedDetails,
                email: emailSubmitted,
              });
              app.setCurrentScreen(ENTER_WALLET_ADDRESS);
            })
            .catch((e) => {
              if (e?.error_id === "transactionAlreadyHasUser") {
                transaction.setSubmittedDetails({
                  ...transaction.submittedDetails,
                  email: emailSubmitted,
                });
                app.setCurrentScreen(ENTER_WALLET_ADDRESS);
              }
            })
            .finally(() => {
              setIsCheckingOTPLogin(false);
            });
        }
      }
    }
    if (
      app.previousScreen === INITIATE_PURCHASE ||
      app.previousScreen === ENTER_EMAIL
    ) {
      postEmailOTP(zusTransactionRef)
        .then((e) => {
          app.setErrorMessage("");
        })
        .catch((e) => {
          if (e?.error_id === "transactionAlreadyHasUser") {
            app.setCurrentScreen(ENTER_WALLET_ADDRESS);
          } else {
            app.setErrorMessage(
              "An error occured while sending OTP, please resend code"
            );
          }
        })
        .finally(() => {
          setIsCheckingOTPLogin(false);
        });
    }
    return () => {
      app.setErrorMessage("");
    };
  }, []);

  useEffect(() => {
    if (!isCodeResent) return;
    const timeout = setTimeout(() => {
      setIsCodeResent(false);
    }, 4000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isCodeResent]);

  const isVerificationCodeValid = () => verificationCode.trim().length === 6;

  const handleContinueClick = () => {
    setIsVerifyingCode(true);
    postConfirmEmailOTP(
      { otp: verificationCode },
      emailSubmitted,
      zusTransactionRef,
      setZusToken
    )
      .then((e) => {
        transaction.setSubmittedDetails({
          ...transaction.submittedDetails,
          email: emailSubmitted,
        });
        app.setErrorMessage("");
        setIsVerifyingCode(false);
        app.setCurrentScreen(ENTER_WALLET_ADDRESS);
      })
      .catch((e) => {
        setVerificationCode("");
        setIsVerifyingCode(false);
        app.setErrorMessage(e?.msg);
      });
  };

  const handleBackClick = () => {
    app.setErrorMessage("");
    app.setCurrentScreen(ENTER_EMAIL);
  };
  const handleResendCode = () => {
    setIsResendingCode(true);
    setVerificationCode("");
    postEmailOTP(zusTransactionRef)
      .then((e) => {
        setIsCodeResent(true);
      })
      .catch((e) => {
        app.setErrorMessage(e?.msg);
      })
      .finally(() => {
        setIsResendingCode(false);
      });
  };
  return (
    <>
      {isCheckingOTPLogin ? (
        <div>
          <LCScreenLoader info="Please hold on..." />
        </div>
      ) : (
        <div className="flex flex-col">
          <LCNavbar hasBack handleBackClick={handleBackClick} />
          <form>
            <div className="flex flex-col items-center justify-between w-full">
              <div className="w-full mb-20 text-center">
                <div className="mb-4 space-y-2">
                  <h1 className="text-base font-semibold text-black">
                    Verification Code
                  </h1>
                  <p className="text-xs text-black-80 w-full max-w-[171px] mx-auto">
                    We sent a 6-digit code to <b>{maskEmail(emailSubmitted)}</b>
                  </p>
                </div>
                <LCInputPin length={6} onChange={setVerificationCode} />
                <div className="flex items-center justify-center mt-4 space-x-1 text-xs">
                  <p className="font-semibold text-black-80">
                    Didn&apos;t receive it?
                  </p>
                  <ResendCode setVerificationCode={setVerificationCode} />
                </div>
              </div>
            </div>
            <LCButton
              type="submit"
              isDisabled={!isVerificationCodeValid()}
              isLoading={isVerifyingCode}
              text="Continue"
              onClick={handleContinueClick}
            />
          </form>
        </div>
      )}
    </>
  );
};

const ResendCode = ({
  setVerificationCode,
}: {
  setVerificationCode: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { colour, setErrorMessage, setToastMessage } = useContext(AppContext);
  const [counter, start, , , isCounting] = useCountDown(30);
  const transactionRef = useTransactionRef();

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: resendOTPMutate, isLoading: resendOTPLoading } = useMutation(
    sendOTP,
    {
      onSuccess: () => setToastMessage("OTP resent"),
      onError: (error: any) => setErrorMessage(error.message),
    }
  );

  const handleResendCode = () => {
    setVerificationCode("");
    start();
    resendOTPMutate({ reference: transactionRef });
  };

  if (resendOTPLoading)
    return (
      <div>
        <div className="animate-spin">
          <i className="ri-loader-4-line" style={{ color: colour.default }} />
        </div>
      </div>
    );

  if (isCounting)
    return (
      <p className="font-medium" style={{ color: colour.default }}>
        Resend in {counter}s
      </p>
    );

  return (
    <button
      type="button"
      onClick={handleResendCode}
      className="font-medium border-none outline-none"
      style={{ color: colour.dark }}
    >
      Resend code
    </button>
  );
};

export default EnterVerificationCode;
