import { PHONE_CODE_LIST } from "../constants/country";
import { add, div, mul, sub } from "./arithmeticFuncs";
import logos from "./logos";
// eslint-disable-next-line import/namespace
import { obj as coinLogoSlug } from "./logoSlugMap";

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const maskEmail = (email: string | undefined) => email?.replace(/^(.{3}).*(?=@)/, "$1***");

export const maskPhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/(\d{5})(\d)(\d{2})/g, "*");

export const maskWalletAddress = (address: string) =>
  `${address.substring(0, 6)}...${address.substring(29)}`;

export const copyToClipboard = async (stuff: string) => {
  try {
    await navigator.clipboard.writeText(stuff);
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

export const before_ = (str = "") =>
  str?.indexOf("_") > 0 ? str?.substring(0, str?.indexOf("_")) : str;

export const beforeHyphen = (str: string) =>
  str?.indexOf("-") > 0 ? str?.substring(0, str?.indexOf("-")) : str;

export const afterHyphen = (str: string) =>
  str?.indexOf("-") > 0 ? str?.substring(str?.indexOf("-") + 1) : str;

export const formatCoinImgNameWithHyphen = (str = "") =>
  beforeHyphen(str).trim().toLowerCase().replaceAll(" ", "-");

export const formatCoinImgNameWithDot = (str = "") =>
  str?.substring(0, str?.indexOf(".")).toLocaleLowerCase().trim();

export const hardCodeCoinImgMap: any = {
  BUSD: "binance-usd",
  USDT: "tether",
  ADX: "adx-net",
  YFII: "yearn-finance-ii",
  LUNA: "terra-luna",
  DOT: "polkadot-new",
  BNB: "binance-coin",
  EGLD: "elrond-egld",
  CTK: "certik",
  BAND: "band-protocol",
  SUSHI: "sushiswap",
  PSG: "paris-saint-germain-fan-token",
  YFI: "yearn-finance",
  HIVE: "hive-blockchain",
  DAI: "multi-collateral-dai",
  INJ: "injective-protocol",
  XLM: "stellar",
  BAR: "fc-barcelona-fan-token",
  CRV: "curve-dao-token",
  IOST: "iostoken",
};

export const findCoinLogo = (option: any) => {
  const formattedKey = (() =>
    Array.isArray(coinLogoSlug[before_(option?.coin)])
      ? coinLogoSlug[before_(option?.coin)][0]
      : coinLogoSlug[before_(option?.coin)] || "")();
  return `https://cdn.jsdelivr.net/gh/godsreal/crypto-icons-plus-32/src/${formattedKey || "coin-fallback"
    }.png`;
};

export const formatProcessorFee = (
  v: {
    type: "FLAT" | "PERCENT";
    fee: number;
  },
  amount: number
) => {
  if (v?.type === "PERCENT") {
    return mul(div(v?.fee, 100, 7), amount, 2);
  }
  return v?.fee;
};

export const roundTo = (num: number, places: string | number) => {
  return Number(Math.round(Number(num + `e+${places}`)) + `e-${places}`);
};

export const commalize = (v = 0, position = 2) =>
  !isNaN(v)
    ? parseFloat(String(v)).toLocaleString("en-US", {
      minimumFractionDigits: position,
    })
    : v;

export const calculateReceiverAmount = (value: {
  senderAmount: number;
  processorFee: number | string;
  exchangeRate: number;
  networkFee: number;
  dpGet?: number;
  dpPay?: number;
}) => {
  if (!value.exchangeRate) return 0;
  return Number(
    sub(
      div(
        sub(value.senderAmount, value.processorFee, value.dpPay),
        value.exchangeRate,
        value.dpGet
      ),
      value.networkFee,
      value.dpGet
    )
  );
};
export const calculateSenderAmount = (value: {
  receiverAmount: number;
  processorFee: { type: "FLAT" | "PERCENT"; fee: string };
  exchangeRate: number;
  networkFee: number;
  dpPay: number;
  dpGet: number;
}) => {
  if (!value.exchangeRate) return 0;
  if (value.processorFee.type === "FLAT") {
    return Number(
      add(
        mul(
          add(value.receiverAmount, value.networkFee, value.dpGet),
          value.exchangeRate,
          value.dpPay
        ),
        value.processorFee.fee,
        value.dpPay
      )
    );
  }
  return Number(
    div(
      mul(
        add(value.receiverAmount, value.networkFee, value.dpGet),
        value.exchangeRate,
        value.dpPay
      ),
      sub(1, div(value.processorFee.fee, 100, 3), 3),
      value.dpPay
    )
  );
};

export const getLogoFor = (logoName: string | undefined) => `https://flagicons.lipis.dev/flags/4x3/${logoName?.toLowerCase()}.svg`


export const sendMessageToParent = (value: { message?: any; key: string }) => {
  if (window && window.parent) {
    window.parent.postMessage(
      {
        key: value.key,
        message: value.message,
      },
      "*"
    );
  }
};

export const getCountryPhoneCode = (country_code = "NG") => {
  return PHONE_CODE_LIST.find((country) => country.code === country_code)?.dial_code || "";
}