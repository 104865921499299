import React, { useContext, useEffect, useState } from "react";
import { AppContext, appContextStore, TransactionContext } from "../App";
import LCSwitchTab from "../components/LCSwitchTab";
import LCNavbar from "../containers/LCNavbar";
import {
  before_,
  commalize,
  copyToClipboard,
  findCoinLogo,
  maskWalletAddress,
} from "../utility";

import LCStepper from "../components/LCStepper";
import LCDescriptionItem from "../components/LCDescriptionItem";
import {
  CHECK_FOR_RECEIVED_AMOUNT_DURATION,
  CHECK_FOR_RECEIVED_AMOUNT_FREQ,
  TRANSACTION_STATE,
} from "../constants/transaction";
import { getTransactionInfo } from "../apis/services";
import { STORAGE_CHECKOUT_DETAILS } from "../constants/storage";
import useTransactionRef from "../hooks/useTransactionRef";

const OrderInfo = () => {
  const app = useContext(AppContext);
  const [shouldShowOrderStatus, setShouldShowOrderStatus] = useState(true);
  const [receivedAmount, setReceivedAmount] = useState();
  const zusTransactionRef = useTransactionRef();

  let timeSpentPolling = 0;

  const transaction = useContext(TransactionContext);

  const handleHelpClick = () => {
    window.location.href = `mailto:support@localramp.co`;
  };

  useEffect(() => {
    if (timeSpentPolling >= CHECK_FOR_RECEIVED_AMOUNT_DURATION) return;
    const interval = setInterval(() => {
      timeSpentPolling += CHECK_FOR_RECEIVED_AMOUNT_FREQ * 1000;

      if (receivedAmount) {
        return;
      }
      getTransactionInfo(zusTransactionRef)
        .then((res) => {
          if (res?.received_amount) {
            transaction.setInfo(res);
            setReceivedAmount(res.received_amount);
          }
        })
        .catch((e) => {
          app.setErrorMessage(e?.msg);
        });
    }, CHECK_FOR_RECEIVED_AMOUNT_FREQ * 1000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [receivedAmount]);

  return (
    <div className="flex flex-col">
      <LCNavbar />
      <div className="">
        <h1 className="text-lg text-black font-medium text-center mb-3">
          Your transaction is complete
        </h1>
        <div className="space-y-7">
          <LCSwitchTab
            handleSwitch={setShouldShowOrderStatus}
            tabOne="Order status"
            tabTwo="Order details"
            value={true}
          />
          <div className="h-[166px]">
            {shouldShowOrderStatus ? (
              <div className="w-full space-y-7">
                <div className="w-8 h-8 rounded-full mx-auto my-8">
                  <img
                    src={findCoinLogo({
                      coin: transaction?.info?.receiver_currency,
                    })}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
                <div className="mx-auto">
                  <LCStepper
                    steps={[
                      { isComplete: true, text: "Ordered" },
                      { isComplete: true, text: "Verified" },
                      { isComplete: true, text: "Processed" },
                      {
                        isComplete:
                          transaction?.info.state ===
                          TRANSACTION_STATE.SENT_CRYPTO,
                        text: "Delivered",
                        isLast: true,
                      },
                    ]}
                  />
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                <LCDescriptionItem
                  title="Order ID"
                  content={transaction.info?.order_id}
                />
                <LCDescriptionItem
                  title="Summary"
                  content={
                    <>
                      {transaction?.info.received_amount ? (
                        <>
                          <span className="font-normal">You got </span>
                          {transaction?.info.received_amount}{" "}
                          {before_(transaction?.info.receiver_currency)}
                          <span className="font-normal"> for </span>
                          {transaction?.info.sender_currency}{" "}
                          {commalize(transaction?.info.sent_amount)}
                        </>
                      ) : (
                        <>...</>
                      )}
                    </>
                  }
                />
                <LCDescriptionItem
                  title={`Delivering ${before_(
                    transaction?.info.receiver_currency
                  )} to`}
                  content={
                    <div className="flex items-center justify-start space-x-2">
                      <div className="w-4 h-4 rounded-full bg-black-20">
                        <img
                          src={findCoinLogo({
                            coin: transaction?.info?.receiver_currency,
                          })}
                          alt=""
                          className="w-full h-full"
                        />
                      </div>
                      <p className="text-xs font-bold">
                        {maskWalletAddress(
                          transaction?.info.destination_address || ""
                        )}
                      </p>
                      <button
                        className="outline-none cursor-pointer"
                        onClick={() => {
                          copyToClipboard(
                            transaction?.info.destination_address || ""
                          ).then(() => {
                            app.setToastMessage("Copied!");
                          });
                        }}
                      >
                        <i className="ri-file-copy-fill ri-lg text-black-40" />
                      </button>
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="bg-[#F2F2F2] px-4 py-2 rounded-lg flex items-center justify-start w-full ">
          <i
            className="ri-lightbulb-line ri-lg"
            style={{
              color: app.colour.default,
            }}
          ></i>
          <p className="text-[10px] text-black-40">
            You may close this at any time. If there are any issues, we&apos;ll
            contact you via email. For more help{" "}
            <button
              onClick={handleHelpClick}
              className="outline-none font-semibold"
              style={{
                color: app.colour.default,
              }}
            >
              click here.
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;
