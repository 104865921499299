import React, { useContext, useState } from "react";
import { AppContext, TransactionContext } from "../App";
import {
  ENTER_WALLET_ADDRESS,
  ENTER_EMAIL,
  ENTER_VERIFICATION_CODE,
  ID_VERIFICATION,
  INITIATE_PURCHASE,
  ORDER_INFO,
  SELECT_PAYMENT_METHOD,
  SEND_TO_BANK_ACCOUNT,
  TRANSACTION_HISTORY,
  VERIFICATION_STATUS,
  VERIFY_WITH_BVN,
  VERIFY_WITH_GOV_ISSUED_ID,
  VERIFY_WITH_NIN,
  TRANSACTION_WARNING,
  VERIFY_ID_VER_CODE,
  APP_ERROR,
  PAYMENT_STATUS,
  WAIT_FOR_MOMO,
  SEND_WITH_MOMO,
  MOMO_OTP,
} from "../constants/screen";
import EnterEmail from "./EnterEmail";
import EnterWalletAddress from "./EnterWalletAddress";
import EnterVerificationCode from "./EnterVerificationCode";
import InitiatePurchase from "./InitiatePurchase";
import OrderInfo from "./OrderInfo";
import SelectPaymentMethod from "./SelectPaymentMethod";
import SendToBankAccount from "./SendToBankAccount";
import TransactionHistory from "./TransactionHistory";
import IDVerification from "./IDVerification";
import VerifyWithBVN from "./VerifyWithBVN";
import VerifyWithNIN from "./VerifyWithNIN";
import VerifyWithGovIssuedID from "./VerifyWithGovIssuedID";
import VerifyIDVerificationCode from "./VerifyIDVerificationCode";
import VerificationStatus from "./VerificationStatus";
import TransactionWarning from "./TransactionWarning";
import AppError from "./AppError";
import PaymentStatus from "./PaymentStatus";
import WaitForPaymentMomo from "./WaitForPaymentMomo";
import SendWithMobile from "./SendWithMobile";
import MomoOTP from "./MomoOTP";

const ScreenManager = () => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);

  const [emailSubmitted, setEmailSubmitted] = useState(
    transaction?.info.email || ""
  );
  const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState("");
  const [bvnSubmitted, setBvnSubmitted] = useState("");
  const [ninSubmitted, setNinSubmitted] = useState("");

  switch (app.currentScreen) {
    case INITIATE_PURCHASE:
      return <InitiatePurchase />;
    case ENTER_EMAIL:
      return (
        <EnterEmail
          tempEmail={emailSubmitted}
          handleSubmittedEmail={setEmailSubmitted}
        />
      );
    case ENTER_VERIFICATION_CODE:
      return <EnterVerificationCode emailSubmitted={emailSubmitted} />;
    case ENTER_WALLET_ADDRESS:
      return <EnterWalletAddress />;
    case SELECT_PAYMENT_METHOD:
      return <SelectPaymentMethod />;
    case SEND_TO_BANK_ACCOUNT:
      return <SendToBankAccount />;
    case SEND_WITH_MOMO:
      return <SendWithMobile />;
    case MOMO_OTP:
      return <MomoOTP />;
    case WAIT_FOR_MOMO:
      return <WaitForPaymentMomo />;
    case ORDER_INFO:
      return <OrderInfo />;
    case ID_VERIFICATION:
      return <IDVerification />;
    case VERIFY_WITH_BVN:
      return (
        <VerifyWithBVN
          handleSubmittedPhoneNumber={setPhoneNumberSubmitted}
          handleSubmittedBVN={setBvnSubmitted}
        />
      );
    case VERIFY_WITH_NIN:
      return (
        <VerifyWithNIN
          handleSubmittedNIN={setNinSubmitted}
          handleReturnedPhoneNumber={setPhoneNumberSubmitted}
        />
      );
    case VERIFY_ID_VER_CODE:
      return (
        <VerifyIDVerificationCode
          ninSubmitted={ninSubmitted}
          bvnSubmitted={bvnSubmitted}
          idPhoneNumberSubmitted={phoneNumberSubmitted}
        />
      );
    case VERIFY_WITH_GOV_ISSUED_ID:
      return <VerifyWithGovIssuedID />;
    case VERIFICATION_STATUS:
      return <VerificationStatus />;
    case PAYMENT_STATUS:
      return <PaymentStatus />;
    case TRANSACTION_HISTORY:
      return (
        <TransactionHistory
          handleHomeClick={() => {
            app.setCurrentScreen(INITIATE_PURCHASE);
          }}
        />
      );
    case TRANSACTION_WARNING:
      return <TransactionWarning />;
    case APP_ERROR:
      return <AppError />;
    default:
      return <InitiatePurchase />;
  }
};

export default ScreenManager;
