import React, { useContext } from "react";
import { AppContext } from "../App";

import CompletedIcon from "../assets/icons/others/stepCompleted.svg";
import DefaultIcon from "../assets/icons/others/stepDefault.svg";

type StepType = {
  isComplete: boolean;
  isLast?: boolean;
  text: string;
};

const LCStep = (props: StepType) => {
  const app = useContext(AppContext);
  return (
    <div className="relative flex items-center justify-start">
      <div
        className={`relative space-y-1 flex items-center justify-center flex-col`}
      >
        <div className={`w-6 h-6 rounded-full`}>
          {props.isComplete ? (
            <img
              src={CompletedIcon}
              alt="completed"
              className="w-full h-full"
            />
          ) : (
            <img src={DefaultIcon} alt="default" className="w-full h-full" />
          )}
        </div>
        <p
          className={`${
            !app.isPopup ? "text-xs" : "text-[10px]"
          } text-center font-semibold text-black`}
        >
          {props.text}
        </p>
      </div>
      {!props.isLast && (
        <div className="relative h-[57px] w-[24px]">
          <div className={`stepper-link`}></div>
        </div>
      )}
    </div>
  );
};

const LCStepper = (props: { steps: StepType[] }) => {
  return (
    <div className="flex items-center justify-center">
      {props.steps.map((step, i) => (
        <LCStep key={i} {...step} />
      ))}
    </div>
  );
};

export default LCStepper;
