import React, { useContext } from "react";
import { AppContext, appContextStore, TransactionContext } from "../App";
import LCBack from "../components/LCBack";
import LCBurgerMenu from "../components/LCBurgerMenu";
import LCNavItem from "../components/LCNavItem";
import { TRANSACTION_HISTORY } from "../constants/screen";
import { STORAGE_TOKEN } from "../constants/storage";
import { getFromStorageLocal, hasStorageAccess } from "../utility/storage";

const LCNavbar = ({
  hasBack = false,
  hasCompanyName = false,
  handleBackClick = () => {},
}: {
  hasBack?: boolean;
  handleBackClick?: () => void;
  hasCompanyName?: boolean;
}) => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);

  const email = transaction?.info.email || transaction?.submittedDetails.email;

  const zusToken = appContextStore(
    (state) => state[`${STORAGE_TOKEN}:${email}`]
  );

  const resetZusToken = appContextStore((state) => state.setToken);

  const handleLogout = () => {
    hasStorageAccess
      ? localStorage.removeItem(`${STORAGE_TOKEN}:${email}`)
      : resetZusToken({
          undefined,
          email,
        });
    app.setIsMenuOpen(!app.isMenuOpen);
  };

  const handleHelpClick = () => {
    window.location.href = `mailto:support@localramp.co`;
  };

  const isTokenInStorage = () =>
    hasStorageAccess
      ? !!getFromStorageLocal(`${STORAGE_TOKEN}:${email}`)
      : !!zusToken;

  return (
    <>
      <div className="w-full">
        <div
          className={`flex pt-2 mb-3 ${
            !hasBack && !hasCompanyName ? "justify-end" : "justify-between"
          } items-center w-full`}
        >
          {hasBack && <LCBack handleClick={handleBackClick} />}
          {hasCompanyName && (
            <h1 className="text-black text-base font-semibold">
              {transaction?.info.merchant_name}
            </h1>
          )}
          <div className="float-right z-40">
            <LCBurgerMenu
              handleClick={() => {
                app.setIsMenuOpen(!app.isMenuOpen);
              }}
            />
          </div>
        </div>
      </div>
      {app?.isMenuOpen && (
        <div className="absolute top-0 right-0 h-full w-full bg-white py-7 px-8 rounded-3xl z-30">
          <h1 className="text-black text-base font-semibold mt-1">Hello!</h1>
          <div className="mt-5">
            {isTokenInStorage() ? (
              <LCNavItem
                isActive
                icon="ri-history-fill"
                text="Transaction History"
                onClick={() => app.setCurrentScreen(TRANSACTION_HISTORY)}
              />
            ) : null}
            <div className="pt-6">
              <LCNavItem
                icon="ri-question-line"
                text="Help"
                onClick={handleHelpClick}
              />
              {isTokenInStorage() ? (
                <LCNavItem
                  icon="ri-logout-box-r-line"
                  text="Logout"
                  extraText={email}
                  onClick={handleLogout}
                />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LCNavbar;
