import React, { useContext, useEffect, useState } from "react";
import { verifyID, verifyIDOTP } from "../apis/services";
import { AppContext, TransactionContext } from "../App";
import LCButton from "../components/LCButton";
import LCInputPin from "../components/LCInputPin";
import {
  SELECT_PAYMENT_METHOD,
  VERIFY_WITH_BVN,
  VERIFY_WITH_NIN,
} from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import useTransactionRef from "../hooks/useTransactionRef";
import { getCountryPhoneCode, maskPhoneNumber } from "../utility";
import { useCountDown } from "../hooks/useCountdown";
import { useMutation } from "react-query";
interface IResendProps {
  setVerificationCode: React.Dispatch<React.SetStateAction<string>>;
  sendCode: (mode: "sms" | "whatsapp") => void;
  counter: number;
  isCounting: boolean;
  isResendingOTP: boolean;
}
const VerifyIDVerificationCode = ({
  idPhoneNumberSubmitted,
  ninSubmitted,
  bvnSubmitted,
}: {
  idPhoneNumberSubmitted: string;
  ninSubmitted: string;
  bvnSubmitted: string;
}) => {
  const app = useContext(AppContext);
  const { info } = useContext(TransactionContext);
  const { previousScreen } = app;
  const zusTransactionRef = useTransactionRef();
  const [counter, start, , , isCounting] = useCountDown(30);

  const [verificationCode, setVerificationCode] = useState("");

  const { mutate: resendOTP, isLoading: isOTPResending } = useMutation(
    verifyID,
    {
      onSuccess: () => {
        app.setToastMessage("OTP resent");
        start();
      },
      onError: (error: any) => app.setErrorMessage(error.response?.data?.msg),
    }
  );
  const { mutate: verifyOTP, isLoading: isVerifyingOTP } = useMutation(
    verifyIDOTP,
    {
      onSuccess: () => app.setCurrentScreen(SELECT_PAYMENT_METHOD),
      onError: (error: any) => app.setErrorMessage(error.response?.data?.msg),
    }
  );

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isVerificationCodeValid = () => verificationCode.trim().length === 6;

  const handleContinueClick = () =>
    verifyOTP({ reference: zusTransactionRef, otp: verificationCode });

  const sendCode = (alert_type: "whatsapp" | "sms") =>
    resendOTP({
      reference: zusTransactionRef,
      type: "bvn",
      id_number:
        previousScreen === VERIFY_WITH_NIN
          ? ninSubmitted
          : previousScreen === VERIFY_WITH_BVN
          ? bvnSubmitted
          : "",
      phone_number:
        previousScreen === VERIFY_WITH_BVN
          ? getCountryPhoneCode(info?.country_code)?.concat(
              idPhoneNumberSubmitted
            ) || ""
          : undefined,
      verification_alert_type: alert_type,
    });

  const handleBackClick = () => app.setCurrentScreen(previousScreen);
  return (
    <div className="flex flex-col">
      <LCNavbar hasBack handleBackClick={handleBackClick} />
      <div className="flex flex-col items-center justify-between w-full">
        <div className="w-full mb-10 text-center">
          <div className="mb-4 space-y-2">
            <h1 className="text-base font-semibold text-black">
              Verification Code
            </h1>
            <p className="text-xs text-black-80 w-full max-w-[171px] mx-auto">
              We sent a 6-digit code to <br />
              {previousScreen === VERIFY_WITH_BVN ? (
                <b>+234{maskPhoneNumber(idPhoneNumberSubmitted)}</b>
              ) : (
                `a number ending with ${idPhoneNumberSubmitted}`
              )}
            </p>
          </div>
          <div className="space-y-4">
            <LCInputPin length={6} onChange={setVerificationCode} />
            <div className="flex items-center justify-center space-x-1 text-xs">
              <p className="font-semibold text-black-80">
                Didn&apos;t receive it?
              </p>
              <ResendCode
                setVerificationCode={setVerificationCode}
                sendCode={sendCode}
                counter={counter}
                isCounting={isCounting}
                isResendingOTP={isOTPResending}
              />
            </div>
            <div className="flex items-center justify-center w-full">
              <ResendCodeWhatsApp
                setVerificationCode={setVerificationCode}
                sendCode={sendCode}
                counter={counter}
                isCounting={isCounting}
                isResendingOTP={isOTPResending}
              />
            </div>
          </div>
        </div>
      </div>
      <LCButton
        isDisabled={!isVerificationCodeValid()}
        isLoading={isOTPResending || isVerifyingOTP}
        text="Continue"
        onClick={handleContinueClick}
      />
    </div>
  );
};

const ResendCodeWhatsApp = ({
  setVerificationCode,
  sendCode,
  isCounting,
  isResendingOTP,
}: IResendProps) => {
  const handleResendCode = () => {
    setVerificationCode("");
    sendCode("whatsapp");
  };

  if (isResendingOTP) return null;

  if (isCounting)
    return (
      <button
        disabled
        className="flex items-center justify-center space-x-1 text-sm outline-none text-[#29773E]"
      >
        <i className="ri-whatsapp-line ri-lg"></i>
        <p className="font-semibold">Send code via WhatsApp</p>
      </button>
    );

  return (
    <button
      onClick={handleResendCode}
      className="flex items-center justify-center space-x-1 text-sm outline-none text-[#29773E]"
    >
      <i className="ri-whatsapp-line ri-lg"></i>
      <p className="font-semibold">Send code via WhatsApp</p>
    </button>
  );
};

const ResendCode = ({
  setVerificationCode,
  sendCode,
  counter,
  isCounting,
  isResendingOTP,
}: IResendProps) => {
  const { colour } = useContext(AppContext);

  const handleResendCode = () => {
    setVerificationCode("");
    sendCode("sms");
  };

  if (isResendingOTP)
    return (
      <div>
        <div className="animate-spin">
          <i className="ri-loader-4-line" style={{ color: colour.default }} />
        </div>
      </div>
    );

  if (isCounting)
    return (
      <>
        <p className="font-medium" style={{ color: colour.default }}>
          Resend in {counter}s
        </p>
      </>
    );

  return (
    <button
      type="button"
      onClick={handleResendCode}
      className="font-medium border-none outline-none"
      style={{ color: colour.dark }}
    >
      Resend code
    </button>
  );
};
export default VerifyIDVerificationCode;
