import moment from "moment";
import React from "react";
import { before_, commalize, findCoinLogo } from "../utility";

interface ILCTransactionListItemProps {
  receiverCurrency: string;
  createdAt: string;
  senderCurrency: string;
  sentAmount: string;
  receivedAmount: string;
}
const LCTransactionListItem = ({
  receiverCurrency,
  createdAt,
  senderCurrency,
  sentAmount,
  receivedAmount,
}: ILCTransactionListItemProps) => {
  return (
    <div className="flex items-center justify-between py-2">
      <div className="flex items-center space-x-2">
        <div className="w-6 h-6 bg-black-40 rounded-full">
          <img
            src={findCoinLogo({
              coin: before_(receiverCurrency),
            })}
            alt=""
          />
        </div>
        <div className="text-left grotesk">
          <p className="text-xs text-black font-medium mb-[2px]">
            Bought {before_(receiverCurrency)}
          </p>
          <p className="text-[10px] font-medium text-black-40">
            {moment(createdAt).format("DD/MM/YYYY, hh:mm a")}
          </p>
        </div>
      </div>

      <div className="text-right grotesk">
        <p className="text-xs text-black font-semibold mb-[2px]">
          {senderCurrency} {commalize(Number(sentAmount))}
        </p>
        <p className="text-[10px] font-medium text-black-40">
          {receivedAmount} {before_(receiverCurrency)}
        </p>
      </div>
    </div>
  );
};

export default LCTransactionListItem;
