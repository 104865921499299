import React, { useContext, useEffect, useState } from "react";
import { getTransactionInfo, postPayNow } from "../apis/services";
import { AppContext, TransactionContext } from "../App";
import LCAccountDetails from "../components/LCAccountDetails";
import LCButton from "../components/LCButton";
import LCScreenLoader from "../components/LCScreenLoader";
import { PAYMENT_STATUS, TRANSACTION_WARNING } from "../constants/screen";
import {
  REFRESH_CONFIRM_PAYMENT_DURATION,
  REFRESH_CONFIRM_PAYMENT_FREQ,
  TRANSACTION_STATE,
} from "../constants/transaction";
import LCNavbar from "../containers/LCNavbar";
import useTransactionRef from "../hooks/useTransactionRef";
import { commalize } from "../utility";
import PaymentConfirmation from "./PaymentConfirmation";

const SendToBankAccount = () => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);

  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const [isShowConfirmingPayment, setIsShowConfirmingPayment] = useState(false);

  const [isFetchingBankDetails, setIsFetchingBankDetails] = useState(
    !transaction.submittedDetails?.bankAccountDetails
  );
  const zusTransactionRef = useTransactionRef();

  let timeSpentPolling = 0;

  useEffect(() => {
    if (transaction?.info?.state === TRANSACTION_STATE.PENDING) {
      if (!transaction.submittedDetails?.bankAccountDetails) {
        postPayNow(
          {
            payment_method_id: transaction?.info?.payment_method_id,
          },
          zusTransactionRef
        )
          .then((data) => {
            transaction.setSubmittedDetails({
              ...transaction.submittedDetails,
              bankAccountDetails: data,
            });
            setIsConfirmingPayment(true);
            setIsFetchingBankDetails(false);
          })
          .catch(() => {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timeSpentPolling >= REFRESH_CONFIRM_PAYMENT_DURATION) return;

    if (transaction?.info?.is_abandoned) {
      app.setCurrentScreen(TRANSACTION_WARNING);
    }

    const interval = setInterval(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeSpentPolling += REFRESH_CONFIRM_PAYMENT_FREQ * 1000;
      getTransactionInfo(zusTransactionRef)
        .then((res) => {
          if (res?.is_abandoned) {
            app.setCurrentScreen(TRANSACTION_WARNING);
            return;
          } else if (res?.state === TRANSACTION_STATE.PENDING) {
            return;
          } else if (
            res?.state !== TRANSACTION_STATE.INITIATED &&
            res?.state !== TRANSACTION_STATE.PENDING
          ) {
            transaction.setInfo(res);
            if (res?.state === TRANSACTION_STATE.PAYMENT_RECEIVED) {
              app.setCurrentScreen(PAYMENT_STATUS);
            } else if (
              res?.state === TRANSACTION_STATE.SENT_CRYPTO ||
              res?.state === TRANSACTION_STATE.SENDING_CRYPTO ||
              res?.state === TRANSACTION_STATE.BOUGHT_CRYPTO ||
              res?.state === TRANSACTION_STATE.COMPLETED
            ) {
              app.setCurrentScreen(PAYMENT_STATUS);
            }
          }
        })
        .catch((e) => {
          app.setErrorMessage(e?.msg);
        });
    }, REFRESH_CONFIRM_PAYMENT_FREQ * 1000);
    return () => clearInterval(interval);
  }, []);

  const handlePaymentMadeClick = () => {
    setIsShowConfirmingPayment(true);
    setIsConfirmingPayment(true);
  };

  return (
    <div className="flex flex-col">
      <LCNavbar />
      {isShowConfirmingPayment ? (
        <PaymentConfirmation
          handleViewBankDetails={() => {
            setIsShowConfirmingPayment(false);
          }}
        />
      ) : isFetchingBankDetails ? (
        <LCScreenLoader info="Fetching bank details" />
      ) : (
        <div className="flex flex-col items-center justify-between w-full">
          <div className="w-full space-y-2 text-center">
            <h1 className="text-base font-semibold text-black">{`Pay NGN ${commalize(
              transaction.submittedDetails.bankAccountDetails?.payment_amount
            )}`}</h1>
            <p className="text-xs py-2 px-3 w-full bg-[#FFF7EB] rounded-lg mb-3 grotesk">
              Make a{" "}
              <span className="text-[#875100] font-bold">
                single bank transfer
              </span>{" "}
              to this account within 30 minutes from your{" "}
              <span className="text-[#875100] font-bold">
                personal bank account
              </span>
            </p>
            <div>
              {/* Account Details */}
              <LCAccountDetails
                bankName={
                  transaction.submittedDetails.bankAccountDetails?.bank_name
                }
                accountNumber={
                  transaction.submittedDetails.bankAccountDetails
                    ?.account_number
                }
                accountName={
                  transaction.submittedDetails.bankAccountDetails?.account_name
                }
                refCode={
                  transaction.submittedDetails.bankAccountDetails
                    ?.optional_bank_ref
                }
              />
              <div className="flex items-center justify-center mt-4 space-x-1">
                <i className="ri-timer-2-line text-orange"></i>
                <p className="text-xs text-black">
                  Should be confirmed in{" "}
                  <span className="font-semibold">5 mins</span>{" "}
                </p>
              </div>
            </div>
            <LCButton
              text={
                isConfirmingPayment ? "View Progress" : "I have made payment"
              }
              onClick={handlePaymentMadeClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SendToBankAccount;
