import React, { useContext, useEffect, useRef, useState } from "react";
import { getTransactionInfo, postVerifyID } from "../apis/services";
import { AppContext, appContextStore, TransactionContext } from "../App";
import { ID_VERIFICATION, VERIFICATION_STATUS } from "../constants/screen";
import { CHECK_FOR_DOCUMENT_STATUS_FREQ } from "../constants/transaction";
import LCNavbar from "../containers/LCNavbar";

import WhiteSpinner from "../assets/icons/others/whiteSpinner.svg";
import { copyToClipboard } from "../utility";
import { STORAGE_CHECKOUT_DETAILS } from "../constants/storage";
import useTransactionRef from "../hooks/useTransactionRef";

export const DOCUMENT_VERIFICATION_STATE = {
  RECEIVED: "received",
  PASSED: "passed",
  FAILED: "failed",
};

const VerifyWithGovIssuedID = () => {
  const app = useContext(AppContext);
  const transaction = useContext(TransactionContext);
  const handleBack = () => {
    app.setCurrentScreen(ID_VERIFICATION);
  };
  const [qrData, setQRData] = useState<
    { qr: string; link: string } | undefined
  >();
  const [isPostingData, setIsPostingData] = useState(true);
  const [isDocumentReceived, setIsDocumentReceived] = useState(false);

  const zusTransactionRef = useTransactionRef();

  useEffect(() => {
    postVerifyID(
      {
        type: "document",
      },
      zusTransactionRef
    )
      .then((res) => {
        if (res?.qr) {
          setQRData(res);
        }
        setIsPostingData(false);
      })
      .catch((e) => {
        app.setErrorMessage(e?.msg);
      });
  }, []);

  let timeSpentPolling = 0;

  const smileIdentityWindow = useRef<Window | null>();

  const openSmileIdentityWindow = () => {
    smileIdentityWindow.current = window.open(qrData?.link);
  };

  const closeSmileIdentityWindow = () => {
    smileIdentityWindow.current?.close();
  };
  useEffect(() => {
    const interval = setInterval(() => {
      timeSpentPolling += CHECK_FOR_DOCUMENT_STATUS_FREQ * 1000;

      getTransactionInfo(zusTransactionRef)
        .then((res) => {
          transaction.setInfo(res);
          if (
            res?.document_verification_state ===
            DOCUMENT_VERIFICATION_STATE.RECEIVED
          ) {
            setIsDocumentReceived(true);
            closeSmileIdentityWindow();
          }
          if (
            res?.document_verification_state ===
            DOCUMENT_VERIFICATION_STATE.FAILED
          ) {
            app.setCurrentScreen(VERIFICATION_STATUS);
          }
          if (
            res?.document_verification_state ===
            DOCUMENT_VERIFICATION_STATE.PASSED
          ) {
            app.setCurrentScreen(VERIFICATION_STATUS);
          }
        })
        .catch((e) => {
          app.setErrorMessage(e?.msg);
        });
    }, CHECK_FOR_DOCUMENT_STATUS_FREQ * 1000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  return (
    <div className="flex flex-col pb-3">
      {isDocumentReceived ? (
        <div className="absolute top-0 left-0 w-full h-full z-40 bg-black/80 rounded-2xl">
          <div className="flex items-center justify-center flex-col space-y-4 w-full h-full">
            <div className="animate-spin w-12 h-12 mx-auto">
              <img src={WhiteSpinner} alt="spinner" className="w-full h-full" />
            </div>
            <p className="font-semibold text-lg text-center text-white">
              Verifying document. Do not close modal
            </p>
          </div>
        </div>
      ) : null}
      <LCNavbar hasBack handleBackClick={handleBack} />
      <div className="flex flex-col w-full justify-between items-center">
        <div className="text-center w-full">
          <div className="space-y-2 mb-5">
            <h1 className="text-black text-lg font-semibold pb-1">
              Government issued ID
            </h1>
            <p className="text-xs grotesk py-2 px-3 w-full bg-[#FFF7EB] rounded-lg text-[#875100]">
              <span className="font-bold">
                Please leave this page open during verification
              </span>
            </p>
            <p className="text-xs grotesk">
              Scan the QR code to continue on phone
            </p>
          </div>
          <div className="text-center space-y-8 mb-4">
            <div className="w-24 h-24 mx-auto">
              {!isPostingData ? (
                <img src={qrData?.qr} alt="qr" className="w-full h-full" />
              ) : (
                <div className="w-full h-full flex flex-col item-center justify-center">
                  <div className="animate-spin">
                    <i className="ri-loader-5-line text-gray-600 ri-xl"></i>
                  </div>
                </div>
              )}
            </div>
            <p className="text-xs">Can’t scan the QR code?</p>
          </div>
          <div className="flex items-center justify-center text-xs space-x-2">
            <button disabled={isPostingData} onClick={openSmileIdentityWindow}>
              <p
                className="font-medium"
                style={{
                  color: app.colour.dark,
                }}
              >
                Continue verification on this device
              </p>
            </button>
            <button
              disabled={isPostingData}
              onClick={() => {
                copyToClipboard(qrData?.link || "").then(() => {
                  app.setToastMessage("Copied!");
                });
              }}
              className="border border-[#E7E5E5] rounded-[4px] w-6 h-6 flex items-center justify-center outline-none"
            >
              <i
                className="ri-link"
                style={{
                  color: app.colour.dark,
                }}
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyWithGovIssuedID;
