import React, { useContext, useState } from "react";
import { postVerifyID } from "../apis/services";
import { AppContext, appContextStore } from "../App";
import LCButton from "../components/LCButton";
import LCInput from "../components/LCInput";
import { COUNTRY_PHONE_CODE } from "../constants/country";
import { ID_VERIFICATION, VERIFY_ID_VER_CODE } from "../constants/screen";
import { STORAGE_CHECKOUT_DETAILS } from "../constants/storage";
import LCNavbar from "../containers/LCNavbar";
import useTransactionRef from "../hooks/useTransactionRef";

const VerifyWithBVN = ({
  handleSubmittedPhoneNumber,
  handleSubmittedBVN,
}: {
  handleSubmittedPhoneNumber: (phone: string) => void;
  handleSubmittedBVN: (bvn: string) => void;
}) => {
  const app = useContext(AppContext);

  const zusTransactionRef = useTransactionRef();

  const [bvn, setBvn] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const handleBack = () => {
    app.setCurrentScreen(ID_VERIFICATION);
  };

  const handleContinueClick = () => {
    setIsSubmittingForm(true);
    postVerifyID(
      {
        type: "bvn",
        verification_alert_type: "sms",
        id_number: bvn,
        phone_number: `${COUNTRY_PHONE_CODE.NG}${phoneNumber}`,
      },
      zusTransactionRef
    )
      .then((res) => {
        handleSubmittedBVN(bvn);
        handleSubmittedPhoneNumber(phoneNumber);
        app.setErrorMessage("");
        app.setCurrentScreen(VERIFY_ID_VER_CODE);
      })
      .catch((err) => {
        app.setErrorMessage(err?.msg);
        setIsSubmittingForm(false);
      });
  };

  const isFormValueValid = () => bvn.length === 11 && phoneNumber.length === 10;

  return (
    <div className="flex flex-col pb-4">
      <LCNavbar hasBack handleBackClick={handleBack} />
      <div className="flex flex-col w-full justify-between items-center">
        <div className="text-center w-full">
          <div className="space-y-2 mb-4">
            <h1 className="text-black text-lg font-semibold pb-1">
              Verify BVN
            </h1>
            <p className="text-xs py-2 px-3 w-full bg-[#FFF7EB] rounded-lg text-[#875100] grotesk">
              Please note: Your BVN is just used for verification
            </p>
            <p className="text-xs grotesk">
              Kindly provide your bank verification number and phone number to
              continue
            </p>
          </div>
        </div>

        <form className="w-full">
          <div className="space-y-5 mb-14">
            <LCInput
              type="number"
              label="Bank Verification Number"
              placeholder="Enter BVN"
              value={bvn}
              onChange={(e) => {
                setBvn(e.target.value);
              }}
            />
            <LCInput
              type="tel"
              label="Phone number"
              placeholder="*** *** ****"
              value={phoneNumber}
              onChange={(e) => {
                if (e.target.value.length > 10) return; // For Nigerian Phone numbers
                setPhoneNumber(e.target.value);
              }}
            />
          </div>
          <LCButton
            type="submit"
            isLoading={isSubmittingForm}
            isDisabled={!isFormValueValid()}
            text="Continue"
            onClick={handleContinueClick}
          />
        </form>
      </div>
    </div>
  );
};

export default VerifyWithBVN;
