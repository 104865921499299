import React from "react";
import LCInput, { ILCInputPropType } from "./LCInput";

const LCLogo = ({ logo }: { logo: string }) => (
  <div className={`w-4 h-4 rounded-full ${logo ? "" : "bg-black-10"}`}>
    <img src={logo} alt="coin" className="w-full h-full" />
  </div>
);

interface ILCInputWithLogo extends ILCInputPropType {
  logo: string;
  error: string;
}
const LCInputWithLogo = (props: ILCInputWithLogo) => {
  return (
    <>
      <LCInput
        {...props}
        error={props.error}
        type="amount"
        extension={<LCLogo logo={props.logo} />}
      />
    </>
  );
};

export default LCInputWithLogo;
