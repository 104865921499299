/* eslint-env browser */

import React from "react";
import { Router } from "@remix-run/router";
import { render } from "react-dom";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";

import "remixicon/fonts/remixicon.css";
import App from "./App";
import AppError from "./screens/AppError";

const APP_ROUTES: Router = createBrowserRouter([
  {
    index: true,
    element: <App />,
    errorElement: <AppError />,
  },
  {
    path: "/:ref",
    element: <App />,
    errorElement: <AppError />,
  },
]);

const Index = () => {
  return <RouterProvider router={APP_ROUTES} />;
};

render(React.createElement(Index), document.getElementById("root"));
