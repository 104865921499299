import { AxiosRequestConfig } from "axios";
import { api, instance } from ".";
import { STORAGE_CHECKOUT_DETAILS, STORAGE_TOKEN } from "../constants/storage";
import {
  getFromStorageLocal,
  getJsonFromStorage,
  hasStorageAccess,
  setInStorageLocal,
  setJsonInStorage,
} from "../utility/storage";
import { ENDPOINTS } from "./endpoints";

export const getSupportedCurrencies = () =>
  api.get(ENDPOINTS.SUPPORTED_CURRENCIES, {
    success: (response) => response.data.data,
  });

export const getQuote = (params: {
  senderCurrency: string;
  receiverCurrency: string;
  countryCode: string;
}) =>
  api.get(
    `${ENDPOINTS.QUOTE}?sender_currency=${params.senderCurrency}&receiver_currency=${params.receiverCurrency}&country_code=${params.countryCode}`,
    {
      success: (response) => response.data.data,
    }
  );

export const getLimit = (params: {
  senderCurrency: string;
  receiverCurrency: string;
}) =>
  api.get(
    `${ENDPOINTS.LIMITS}?sender_currency=${params.senderCurrency}&receiver_currency=${params.receiverCurrency}`,
    {
      success: (response) => response.data.data,
    }
  );

export const getCheckoutDetails = (
  params: { linkRef: string },
  setZusCheckout: (data: any) => void
) =>
  api.get(`${ENDPOINTS.CHECKOUT_DETAILS}/${params.linkRef}`, {
    success: (response) => {
      if (hasStorageAccess) {
        setJsonInStorage(STORAGE_CHECKOUT_DETAILS, response?.data?.data);
      } else {
        setZusCheckout(response?.data?.data);
      }

      instance.interceptors.request.use((config): AxiosRequestConfig<any> => {
        config.headers = {
          ...config.headers,
          "x-auth-token": response?.data?.data?.public_key,
        };
        return config;
      });

      return response.data.data;
    },
  });

export const getTransactionInfo = (zusRef: string) =>
  api.get(
    `${ENDPOINTS.TRANSACTION_INFO}/${
      !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference
    }`,
    {
      success: (response) => {
        return response.data.data;
      },
    }
  );

export const updateAmount = (data: any, zusRef: string) => {
  return api.put(
    `${ENDPOINTS.UPDATE_AMOUNT}`,
    {
      ...data,
      reference: !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference,
    },
    {
      success: (response) => {
        return response.data.data;
      },
    }
  );
};

export const postEmail = (data: any, zusRef: string) => {
  return api.post(
    `${ENDPOINTS.SET_EMAIL}`,
    {
      ...data,
      reference: !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference,
    },
    {
      success: (response) => {
        return response.data.data;
      },
    }
  );
};
export const postEmailOTP = (zusRef: string) => {
  return api.post(
    `${ENDPOINTS.SET_EMAIL_OTP}`,
    {
      reference: !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference,
    },
    {
      success: (response) => {
        return response.data.data;
      },
    }
  );
};
export const postConfirmEmailOTP = (
  data: any,
  email: string,
  zusRef: string,
  setZus: (p: { value: string; email: string }) => void
) => {
  return api.put(
    `${ENDPOINTS.EMAIL_OTP_CONFIRM}`,
    {
      ...data,
      reference: !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference,
    },
    {
      success: (response) => {
        if (hasStorageAccess) {
          setInStorageLocal(`${STORAGE_TOKEN}:${email}`, response.data.data);
        } else {
          setZus({ value: response.data.data, email });
        }
        return response.data.data;
      },
    }
  );
};

export const signInWithToken = (
  email: string,
  zusPayload: { reference: string; user_token: string }
) => {
  let payload = {
    reference: "",
    user_token: "",
  };
  if (hasStorageAccess) {
    payload.reference = getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference;
    payload.user_token = getFromStorageLocal(`${STORAGE_TOKEN}:${email}`) || "";
  } else {
    payload = zusPayload;
  }
  return api.post(`${ENDPOINTS.SIGNIN_WITH_TOKEN}`, payload, {
    success: (response) => {
      return response.data.data;
    },
  });
};

export const postDestinationAddress = (
  data: {
    destination_tag?: string;
    destination_address: string;
  },
  zusRef: string
) => {
  const formattedData = { ...data };
  if (!data.destination_tag?.trim().length) {
    delete formattedData.destination_tag;
  }
  return api.put(
    `${ENDPOINTS.SET_ADDRESS}`,
    {
      ...formattedData,
      reference: !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference,
    },
    {
      success: (response) => {
        return response.data.data;
      },
    }
  );
};

export const getRequiresVerification = (zusRef: string) =>
  api.get(
    `${ENDPOINTS.REQUIRES_VERIFICATION}/${
      !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference
    }`,
    {
      success: (response) => {
        return response.data.data;
      },
    }
  );

export const getVerificationMethods = (data: { senderCurrency: string }) =>
  api.get(`${ENDPOINTS.VERIFICATION_TYPES}?currency=${data.senderCurrency}`, {
    success: (response) => {
      return response.data.data;
    },
  });

export const getPaymentMethods = ({
  currency,
  country_code,
  reference,
}: {
  currency: string;
  country_code: string;
  reference: string;
}) =>
  api.get(
    `${ENDPOINTS.PAYMENT_METHODS}?currency=${currency}&country_code=${country_code}&reference=${reference}`,
    {
      success: (response) => {
        return response.data.data;
      },
    }
  );

export const postPayNow = (
  data: {
    payment_method_id: string;
    phone_number?: string;
    network?: string;
  },
  zusRef: string
) => {
  return api.post(
    `${ENDPOINTS.PAY_NOW}/${
      !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference
    }`,
    data,
    {
      success: (response) => {
        return response.data.data;
      },
    }
  );
};

export interface IVerifyID {
  verification_alert_type?: "sms" | "whatsapp";
  id_number?: string;
  type: "nin" | "bvn" | "document" | "";
  phone_number?: string;
}
export const postVerifyID = (data: IVerifyID, zusRef: string) => {
  return api.post(
    `${ENDPOINTS.IDENTITY_VERIFICATION}`,
    {
      reference: !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference,
      ...data,
    },
    {
      success: (response) => {
        return response.data.data;
      },
    }
  );
};

export const postCompleteIDVerification = (
  data: { otp?: string },
  zusRef: string
) => {
  return api.post(
    `${ENDPOINTS.COMPLETE_IDENTITY_VERIFICATION}`,
    {
      reference: !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference,
      ...data,
    },
    {
      success: (response) => {
        return response.data.data;
      },
    }
  );
};

export const getTransactionHistory = (
  email: string,
  params: {
    page: number;
    limit: number;
  },
  zusToken: string
) =>
  api.post(
    `${ENDPOINTS.TRANSACTION_HISTORY}?page=${params.page}&limit=5`,
    {
      user_token: hasStorageAccess
        ? getFromStorageLocal(`${STORAGE_TOKEN}:${email}`)
        : zusToken,
    },
    {
      success: (response) => response.data.data,
      failure(x) {
        if (x?.response) {
          throw (
            x.response?.data?.message ||
            x.response?.data?.msg ||
            x.response?.data?.errors[0]
          );
        }
      },
    }
  );

type setDestinationType = {
  data: {
    payment_method_id: string;
    phone_number: string;
    phone_network: string;
    country_code: string;
  };
  zusRef: string;
};
export const setDestination = ({ data, zusRef }: setDestinationType) =>
  instance.post(
    `${ENDPOINTS.PAY_NOW}/${
      !hasStorageAccess
        ? zusRef
        : getJsonFromStorage(STORAGE_CHECKOUT_DETAILS)?.reference
    }`,
    data
  );

export const sendOTP = (data: any) =>
  instance.post(`${ENDPOINTS.SET_EMAIL_OTP}`, data);

type VerifyId = {
  reference: string;
  type: "bvn" | "nin" | "document";
  id_number?: string;
  phone_number?: string;
  verification_alert_type?: "whatsapp" | "sms";
};

export const verifyID = (data: VerifyId) =>
  instance.post(ENDPOINTS.IDENTITY_VERIFICATION, data);

export const verifyIDOTP = (data: { reference: string; otp: string }) =>
  instance.post(ENDPOINTS.COMPLETE_IDENTITY_VERIFICATION, data);

export const sendMomoOTP = (data: { reference: string; otp: string }) =>
  instance.post(ENDPOINTS.MOMO_OTP, data);
