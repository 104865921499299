import { useEffect, useRef, useState } from "react";

type TuseCountDown = (total: number, ms?: number) => [number, () => void, () => void, () => void, boolean]

export const useCountDown: TuseCountDown = (
  total: number,
  ms = 1000
) => {
  const [counter, setCountDown] = useState(total);
  const [startCountDown, setStartCountDown] = useState(false);
  // Store the created interval
  const intervalId = useRef<any>();
  const start: () => void = () => setStartCountDown(true);
  const pause: () => void = () => setStartCountDown(false);
  const reset: () => void = () => {
    clearInterval(intervalId.current);
    setStartCountDown(false);
    setCountDown(total);
  };

  useEffect(() => {
    intervalId.current = setInterval(() => {
      startCountDown && counter > 0 && setCountDown((counter) => counter - 1);
    }, ms);
    // Clear interval when count to zero
    if (counter === 0) reset();
    // Clear interval when unmount
    return () => clearInterval(intervalId.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCountDown, counter, ms]);

  return [counter, start, pause, reset, startCountDown];
};
