import axios, { AxiosRequestConfig } from "axios";
import { STORAGE_CHECKOUT_DETAILS } from "../constants/storage";
import { getFromStorage, getJsonFromStorage } from "../utility/storage";
import { BASE_URL } from "./endpoints";
import { IAPI, IHTTPMethodsParams } from "./types";

export const instance = axios.create({
  baseURL: `${BASE_URL}/v1/widget/buy`,
  // headers: { "x-auth-token": getFromStorage("app.PublicKey") },
});
export const api: IAPI = {
  get: (
    endpoint: string,
    { success, failure, final }: IHTTPMethodsParams
  ): Promise<any> =>
    instance
      .get(endpoint)
      .then(function (response) {
        return success(response);
      })
      .catch(function (error) {
        if (failure) {
          failure(error);
        } else {
          throw error?.response?.data;
        }
      })
      .finally(function () {
        final && final();
      }),
  put: (
    endpoint: string,
    body: any,
    { success, failure, final }: IHTTPMethodsParams
  ): Promise<any> =>
    instance
      .put(endpoint, body)
      .then(function (response) {
        return success(response);
      })
      .catch(function (error) {
        if (failure) {
          failure(error);
        } else {
          throw error?.response?.data;
        }
      })
      .finally(function () {
        final && final();
      }),
  post: (
    endpoint: string,
    body: any,
    { success, failure, final }: IHTTPMethodsParams
  ): Promise<any> =>
    instance
      .post(endpoint, body)
      .then(function (response) {
        return success(response);
      })
      .catch(function (error) {
        if (failure) {
          failure(error);
        } else {
          throw error?.response?.data;
        }
      })
      .finally(function () {
        final && final();
      }),
};
