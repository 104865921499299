import chroma from "chroma-js";
import React, { ReactNode, useContext } from "react";
import { AppContext } from "../App";

export const LCClickableListItem = ({
  icon,
  title,
  extra,
  isDisabled = false,
  handleClick = () => {},
}: {
  icon: string;
  title: string;
  extra?: ReactNode;
  isDisabled?: boolean;
  handleClick: () => void;
}) => {
  const app = useContext(AppContext);
  return (
    <button
      onClick={handleClick}
      className="w-full outline-none clickable-list-item"
    >
      <div
        className={`px-4 py-3 flex items-center justify-between`}
        style={{ backgroundColor: chroma(app.colour.default).alpha(0.1).hex() }}
        role={"button"}
      >
        <div className="flex items-center space-x-1">
          <i
            className={`${icon} ri-md`}
            style={{ color: chroma(app.colour.dark).alpha(0.5).hex() }}
          />
          <p
            className={`text-xs font-semibold`}
            style={{
              color: isDisabled
                ? chroma(app.colour.darker).alpha(0.4).hex()
                : app.colour.darker,
            }}
          >
            {title}
          </p>
        </div>
        <div>{extra}</div>
      </div>
    </button>
  );
};

export const LCClickableList = ({ children }: { children: ReactNode }) => {
  return <div className="clickable-list">{children}</div>;
};
